<script setup lang="ts">
import type { LogisticsTaskType, MachineryAccessoryCategory, MachineryCategories, MachineryDrives, OfferPositionsToShip, UpsertLogisticsDetails } from '~/types'
import { logisticsConditionRatingSchema, logisticsVisualDetailsPollutionsSchema } from '~/server/schemas'
import { logisticsConditionRatingToGerman, logisticsVisualDetailsPollutionToGerman, machineryAccessoryCategoryToGerman } from '~/translations'
import type { RowData } from '~/server/trpc/routers/calendar'

const props = defineProps<{
  payload: UpsertLogisticsDetails
  machineryId?: string | null
  category: MachineryCategories | undefined
  drive: MachineryDrives | undefined
  submitLabel: string
  availableMachineryAccessories: RowData[]
  logisticsTaskType: LogisticsTaskType
  positionTypeToUpdate: OfferPositionsToShip
  machineryIdToUpdate: string
  // for MachineryAccessory only
  isAttachedToMachinery?: boolean
  isInSet?: boolean
  // to connect an eventual Defect to a specific Offer
  offerId?: string
}>()
const emit = defineEmits<{ (e: 'save', payload: UpsertLogisticsDetails): void }>()

const { cloned } = useMightyCloned(props.payload)

// Computed Props
const operatingHoursUpdatedAtBy = computed(() => cloned.value.receptionMachinery?.operatingHoursUpdatedAt && cloned.value.receptionMachinery?.operatingHoursUpdatedByEmail
  ? `Zuletzt aktualisiert von ${cloned.value.receptionMachinery?.operatingHoursUpdatedByEmail} (${useDateAsString(cloned.value.receptionMachinery?.operatingHoursUpdatedAt)})`
  : undefined)

const isMachineryVisibile = computed(() => props.positionTypeToUpdate === 'machinery')
const isAccessoryVisible = computed(() => ['machineryAccessoryCategory', 'machineryAccessory'].includes(props.positionTypeToUpdate))
const isItemSetVisible = computed(() => props.positionTypeToUpdate === 'itemSet')
const showMachineryDetails = computed(() => props.category !== 'trailer')

const tankFillingValidation = computed(() => {
  const validation = [['min', 0]]

  const isInbound = props.logisticsTaskType === 'inbound'
  const isTankEmpty = cloned.value.logisticsMachineryDetails?.isTankFull === false
  if (isInbound && isTankEmpty) {
    validation.unshift(['required'])
  }

  return validation
})

// Composables
const formkitPlugins = useFormkitPlugins()
const {
  logisticsDetailsFieldsByMachineCategory,
  logisticsMachineryDetailsFieldsByMachineCategory,
  logisticsFunctionalDetailsFieldsByMachineCategory,
  logisticsVisualDetailsFieldsByMachineCategory,
  logisticsConditionDetailsFieldsByMachineCategory,
} = useFormOptionalConditions()

const { openDefectCreateOrUpdatePopup } = useGlobalOpeners()

// Options
const pollutionOptions = computed(() => logisticsVisualDetailsPollutionsSchema.options.map(option => ({
  label: logisticsVisualDetailsPollutionToGerman[option],
  value: option,
})))
const ratingOptions = computed(() => logisticsConditionRatingSchema.options.map(option => ({
  label: logisticsConditionRatingToGerman[option],
  value: option,
})))
function machineryAccessoryOptions(category: MachineryAccessoryCategory) {
  const machineryAccessoryCategoryWithAccessories = props.availableMachineryAccessories.find(acc => acc.id === category)
  return machineryAccessoryCategoryWithAccessories?.children?.map(acc => ({
    value: acc.id,
    label: acc.name,
  })) ?? []
}
</script>

<template>
  <FormKit
    v-slot="{ value }"
    v-model="cloned"
    type="form"
    :plugins="[formkitPlugins]"
    :submit-label="submitLabel"
    @submit="emit('save', cloned)"
  >
    <TheDevOnlyNiceJson :value="value" :cloned="cloned" />
    <div
      v-if="category && isMachineryVisibile
        // these fields will be empty when we are only updating accessries
        && cloned.logisticsMachineryDetails && cloned.logisticsFunctionalDetails && cloned.logisticsVisualDetails && cloned.logisticsConditionDetails"
      class="w-full flex flex-col gap-y-2 mb-6"
    >
      <div
        v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'comment', category, drive)"
        class="WidthHalfMinusGap"
      >
        <FormKit
          id="comment"
          label="Kommentar"
          type="text"
        />
      </div>
      <h2 v-if="showMachineryDetails" class="Heading">
        Gerätedaten
      </h2>
      <div v-if="showMachineryDetails" class="flex gap-y-2 sm:gap-y-0 sm:gap-x-2 flex-col sm:flex-row flex-wrap">
        <FormKit id="logisticsMachineryDetails" type="group">
          <div
            v-if="useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'tankFilling', category, drive)"
            class="WidthHalfMinusGap"
          >
            <FormKit
              id="tankFilling"
              label="Tankfüllung (Liter)"
              type="number"
              number
              placeholder="15"
              :validation="tankFillingValidation"
              validation-visibility="live"
            />
          </div>
          <div
            v-if="useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'adblueFilling', category, drive)"
            class="WidthHalfMinusGap"
          >
            <FormKit
              id="adblueFilling"
              label="AdBlue (Liter)"
              type="number"
              number
              validation="min:0"
              placeholder="50"
            />
          </div>
        </FormKit>

        <div
          v-if="useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'isTankFull', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsMachineryDetails.isTankFull"
            label="Tank ist voll"
            validation="required"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'operatingHours', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKit id="receptionMachinery" type="group">
            <FormKit
              v-if="cloned.receptionMachinery"
              id="operatingHours"
              label="Betriebsstunden Zähler (Stunden)"
              type="number"
              number
              validation="min:0"
              step="0.1"
              placeholder="36"
              :help="operatingHoursUpdatedAtBy"
            />
          </FormKit>
        </div>
        <FormKit id="logisticsMachineryDetails" type="group">
          <div
            v-if="useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'ignitionKeys', category, drive)"
            class="WidthHalfMinusGap"
          >
            <FormKit
              id="ignitionKeys"
              label="Zündschlüssel (Anzahl)"
              type="number"
              number
              validation="required|min:0"
              step="0.01"
              placeholder="36"
            />
          </div>
          <div
            v-if="useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'gasTanks', category, drive) && props.category !== 'crane' && !(props.category === 'forklift' && props.drive === 'electric')"
            class="WidthHalfMinusGap"
          >
            <FormKit
              id="gasTanks"
              label="Gasflaschen (insgesamt)"
              type="number"
              number
              validation="required|min:0"
              placeholder="36"
            />
          </div>
          <div
            v-if="logisticsTaskType === 'inbound' && useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'emptyGasTanks', category, drive) && props.category !== 'crane' && !(props.category === 'forklift' && props.drive === 'electric')"
            class="WidthHalfMinusGap"
          >
            <FormKit
              id="emptyGasTanks"
              label="Leere Gasflaschen"
              type="number"
              validation="required|min:0"
              placeholder="36"
            />
          </div>
          <div
            v-if="useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'tuvValiditedAt', category, drive)"
            class="WidthHalfMinusGap"
          >
            <FormKitDate
              id="tuvValiditedAt"
              label="TÜV"
            />
          </div>
          <div
            v-if="useShowFieldByFilters(logisticsMachineryDetailsFieldsByMachineCategory, 'securityCheckValiditedAt', category, drive)"
            class="WidthHalfMinusGap"
          >
            <FormKitDate
              id="securityCheckValiditedAt"
              label="SP"
            />
          </div>
        </FormKit>
      </div>
      <h2 class="Heading">
        Funktionsprüfung
      </h2>
      <div class="flex gap-x-4 flex-wrap">
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'areEngineAndGearboxWorking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.areEngineAndGearboxWorking"
            label="Motor, Getriebe"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isBreakWorking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.isBreakWorking"
            label="Bremsen"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isSteerwheelWorking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.isSteerwheelWorking"
            label="Lenkung"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'areMastAndChainAndBearingWorking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.areMastAndChainAndBearingWorking"
            label="Hubgerüst, Kette, Lager"
            :validation="false"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isElectricalSystemWorking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.isElectricalSystemWorking"
            label="Elektrische Anlage"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isMachineryAccessory', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.isMachineryAccessory"
            label="Anbaugeräte"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isSecurityEquipmentWorking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.isSecurityEquipmentWorking"
            label="Sicherheitseinrichtung"
            :validation="false"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isLightingWorking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.isLightingWorking"
            label="Beleuchtung"
            :validation="false"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isBatteryWorking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.isBatteryWorking"
            label="Batterie"
            :validation="false"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'areWarningLightAndHornWorking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.areWarningLightAndHornWorking"
            label="Warnlicht, Hupe"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isSafetyShutdownWorking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.isSafetyShutdownWorking"
            label="Sicherheitsabschaltung"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isHydraulicSystemWorking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.isHydraulicSystemWorking"
            label="Hydrauliksystem"
            :validation="false"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'isGpsWorking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsFunctionalDetails.isGpsWorking"
            label="GPS in Ordnung"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsFunctionalDetailsFieldsByMachineCategory, 'note', category, drive)"
          class="w-full"
        >
          <FormKit id="logisticsFunctionalDetails" type="group">
            <FormKit
              id="note"
              label="Bemerkung"
              type="textarea"
            />
          </FormKit>
        </div>
      </div>
      <h2 class="Heading">
        Sichtprüfung
      </h2>
      <div class="flex gap-x-2 flex-wrap">
        <div
          v-if="useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isMachineryComplete', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsVisualDetails.isMachineryComplete"
            label="Gerät komplett"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'hasExternalDamage', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsVisualDetails.hasExternalDamage"
            label="Äußere Schäden"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isLeaking', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsVisualDetails.isLeaking"
            label="Leckagen"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isOilLevelOk', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsVisualDetails.isOilLevelOk"
            label="Ölstand OK"
            :validation="false"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isCoolantLevelOk', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsVisualDetails.isCoolantLevelOk"
            label="Kühlwasserstand OK"
            :validation="false"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isMachineryCleaned', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsVisualDetails.isMachineryCleaned"
            label="Gerät gereinigt"
            :validation="false"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'pollution', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKit id="logisticsVisualDetails" type="group">
            <FormKit
              id="pollution"
              label="Verschmutzung"
              type="select"
              :options="pollutionOptions"
            />
          </FormKit>
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'isTapeMeasureAvailable', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsVisualDetails.isTapeMeasureAvailable"
            label="Masseband vorhanden"
            :validation="false"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsVisualDetailsFieldsByMachineCategory, 'note', category, drive)"
          class="w-full"
        >
          <FormKit id="logisticsVisualDetails" type="group">
            <FormKit
              id="note"
              label="Bemerkung"
              type="textarea"
            />
          </FormKit>
        </div>
      </div>
      <h2 class="Heading">
        Zustand
      </h2>
      <n-button v-if="logisticsTaskType === 'inbound' && machineryId" @click="openDefectCreateOrUpdatePopup.open({ itemId: machineryIdToUpdate, type: 'machinery', mode: 'create', offerId })">
        <template #icon>
          <Icon name="material-symbols:edit-outline-rounded" />
        </template>Defekt
      </n-button>
      <n-collapse v-if="logisticsTaskType === 'inbound'" class="mb-2">
        <n-collapse-item title="Gerätezustand-Übersicht">
          <DefectPage :additional-filters="{ machineryId }" overview-title="" />
        </n-collapse-item>
      </n-collapse>
      <div class="flex gap-x-2 flex-wrap">
        <div
          v-if="useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'roofAndCabin', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsConditionDetails.roofAndCabin"
            label="FS - Dach / Kabine"
            :options="ratingOptions"
            :validation="false"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'tiresAirAndSE', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsConditionDetails.tiresAirAndSE"
            label="Reifen Luft / SE"
            :options="ratingOptions"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'forkesAndShovels', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsConditionDetails.forkesAndShovels"
            label="Gabeln / Schaufeln"
            :options="ratingOptions"
            :validation="false"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'painting', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsConditionDetails.painting"
            label="Lackierung"
            :options="ratingOptions"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'driverSeat', category, drive)"
          class="WidthHalfMinusGap"
        >
          <FormKitRadioWithReset
            v-model="cloned.logisticsConditionDetails.driverSeat"
            label="Fahrersitz"
            :options="ratingOptions"
            :validation="false"
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsConditionDetailsFieldsByMachineCategory, 'note', category, drive)"
          class="w-full"
        >
          <FormKit id="logisticsConditionDetails" type="group">
            <FormKit
              id="note"
              label="Bemerkung"
              type="textarea"
            />
          </FormKit>
        </div>
      </div>
      <h2 class="Heading">
        Fotos
      </h2>
      <div class="flex gap-x-2 flex-wrap">
        <div
          v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'generalPhotos', category, drive)"
          class="w-full"
        >
          <FormKitFileDropzone
            id="generalPhotos"
            label="Allgemein (Min 2)"
            validation="required|min:2"
            wrap-path-in-object
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'innerCabinPhotos', category, drive)"
          class="w-full"
        >
          <FormKitFileDropzone
            id="innerCabinPhotos"
            label="Kabine innen (Min 2)"
            :validation="`${category !== 'crane' ? 'required|' : ''}min:2`"
            wrap-path-in-object
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'outerCabinPhotos', category, drive)"
          class="w-full"
        >
          <FormKitFileDropzone
            id="outerCabinPhotos"
            label="Kabine außen (Min 2)"
            :validation="`${category !== 'crane' ? 'required|' : ''}min:2`"
            wrap-path-in-object
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'tiresPhotos', category, drive)"
          class="w-full"
        >
          <FormKitFileDropzone
            id="tiresPhotos"
            label="Reifen (Min 4)"
            validation="required|min:4"
            wrap-path-in-object
          />
        </div>
        <div
          v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'enginePhotos', category, drive)"
          class="w-full"
        >
          <FormKitFileDropzone
            id="enginePhotos"
            label="Motor"
            wrap-path-in-object
          />
        </div>
      </div>
    </div>

    <div v-if="cloned.logisticsAccessoryDetails && (isAccessoryVisible || isItemSetVisible)" class="w-full mb-6">
      <h2 class="Heading">
        {{ positionTypeToUpdate === 'itemSet' ? 'Set' : 'Anbaugeräte' }}
      </h2>
      <div v-if="cloned.logisticsAccessoryDetails.itemSetId">
        <b class="mr-3">Set-Id:</b>
        <span>{{ cloned.logisticsAccessoryDetails.itemSetId }}</span>
      </div>
      <div v-else-if="!cloned.logisticsAccessoryDetails.machineryAccessoryId">
        <div class="mb-3">
          <b class="mr-3">Geräte-Kategorie:</b>
          <span>{{ cloned.logisticsAccessoryDetails.machineryAccessoryCategory ? machineryAccessoryCategoryToGerman[cloned.logisticsAccessoryDetails.machineryAccessoryCategory as MachineryAccessoryCategory] : 'N/A' }}</span>
        </div>
        <FormKit id="logisticsAccessoryDetails" type="group">
          <FormKit
            id="machineryAccessoryId"
            label="Geräte-Id"
            type="select"
            placeholder="Bitte auswählen"
            validation="required"
            :options="machineryAccessoryOptions(cloned.logisticsAccessoryDetails.machineryAccessoryCategory as MachineryAccessoryCategory)"
          />
        </FormKit>
      </div>
      <div v-else>
        <b class="mr-3">Geräte-Id:</b>
        <span>{{ cloned.logisticsAccessoryDetails.machineryAccessoryId }}</span>
      </div>
      <div class="flex gap-x-2 flex-wrap">
        <div class="WidthHalfMinusGap">
          <FormKitRadioWithReset
            v-model="cloned.logisticsAccessoryDetails.isMachineryAccessoryComplete"
            label="Gerät komplett"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKitRadioWithReset
            v-model="cloned.logisticsAccessoryDetails.hasExternalDamage"
            label="Äußere Schäden"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKitRadioWithReset
            v-model="cloned.logisticsAccessoryDetails.isFunctioning"
            label="Funktionsprüfung"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            v-if="logisticsTaskType === 'inbound' && !isAttachedToMachinery && !isInSet"
            id="storageLocation"
            type="text"
            label="Lagerplatz"
            placeholder="Crailsheim"
          />
        </div>
        <div class="w-full">
          <FormKit id="logisticsAccessoryDetails" type="group">
            <FormKit
              id="note"
              label="Bemerkung"
              type="textarea"
            />
          </FormKit>
        </div>
        <div
          v-if="logisticsTaskType === 'inbound' && cloned.logisticsAccessoryDetails.machineryAccessoryId"
          class="w-full mb-5 flex flex-col gap-y-2"
        >
          <p class="font-bold">
            Zustand
          </p>
          <n-button
            @click="openDefectCreateOrUpdatePopup.open({ itemId: cloned.logisticsAccessoryDetails.machineryAccessoryId, type: 'machineryAccessory', mode: 'create', offerId })"
          >
            <template #icon>
              <Icon name="material-symbols:edit-outline-rounded" />
            </template>Defekt
          </n-button>
          <n-collapse v-if="logisticsTaskType === 'inbound'" class="mb-2">
            <n-collapse-item title="Lagertool-Übersicht">
              <DefectPage :additional-filters="{ machineryAccessoryId: cloned.logisticsAccessoryDetails.machineryAccessoryId }" overview-title="" />
            </n-collapse-item>
          </n-collapse>
        </div>
        <div class="w-full">
          <FormKit id="logisticsAccessoryDetails" type="group">
            <FormKitFileDropzone
              id="photos"
              label="Anbaugerät Foto (Min 1)"
              validation="required|min:1"
            />
          </FormKit>
        </div>
      </div>
    </div>
  </FormKit>
</template>

<style scoped>
.Heading {
  @apply text-xl font-bold py-4
}
.WidthHalfMinusGap {
  width: calc(50% - 0.5rem)
}
.RemoveCheckboxBackground :deep(.formkit-inner) {
  background: none;
}
</style>
