<script setup lang="ts">
const { t: $t } = useI18n()

import type { MenuOption } from 'naive-ui'
import { useRouteQuery } from '@vueuse/router'
import { endOfToday, startOfToday } from 'date-fns'
import { withQuery } from 'ufo'
import type { ApiMachineryGetById, CalendarPageOfferCreationModeEmit, CreateQuickSale, MachineryCategories, MachineryDrives, MachineryInsuranceDeductible, MachineryPurchasing, MachineryRubrics, MachineryStatus, UpdateMachineryOperationHours, UpdateMachineryType } from '~/types'
import { type UserRole, internalReservationRoles } from '~/authorization'
import { machineryDrivesToGerman } from '~/translations'

const props = defineProps<{ machineryId: string, view?: string, isUpdateDisabled?: boolean }>()

const { canAccessPage, isRole } = useAuthorization()
const activeMenu = useRouteQuery('view', props.view ?? 'all')
const isForeignMachinery = useRouteQuery('isForeignMachinery', undefined, {
  transform: val => val === 'true',
})

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const { machinery: queryMachinery, receptionMachinery: queryReceptionMachinery, receptionMachineryHistory: queryReceptionMachineryHistory, machineryType: queryMachineryType, offerPosition: queryOfferPosition, internalReservation: queryInternalReservation } = useQuery()

const { data: machinery, isLoading: isLoadingMachinery, error: errorMachinery } = queryMachinery.byId(ref(props.machineryId), { defects: { where: { repairedAt: null } } }, undefined, isForeignMachinery.value)

const { data: isReleasedForCurrentUser } = queryMachinery.isReleasedForCurrentUser(ref(props.machineryId))

const { data: receptionMachinery, isLoading: isLoadingReceptionMachinery, error: errorReceptionMachinery } = queryReceptionMachinery.byMachineryId(ref(props.machineryId))
const { data: machineryTypes } = queryMachineryType.all()

const isPartner = computed(() => isRole('partner-sales', false))
const receptionMachineryId = computed(() => receptionMachinery.value?.id)
const machineryCategory = computed(() => machinery.value?.category ?? '')

const { data: receptionMachineryHistory, isLoading: isLoadingReceptionMachineryHistory } = queryReceptionMachineryHistory.allForOverview(receptionMachineryId, !isPartner.value)

const { data: overlappingInternalReservations } = queryInternalReservation.getOverlappingReservations(
  [props.machineryId],
  startOfToday(),
  endOfToday(),
)

const offerPositionWhere = computed(() => ({
  machineryId: props.machineryId,
  isNotReturnable: true,
}))
const offerPositionEnabled = computed(() => machinery.value?.isPermanentlyNotAvailable ?? false)
const { data: notReturnableOfferPositions } = queryOfferPosition.all(offerPositionWhere, offerPositionEnabled)

const statusHistory = computed((): MachineryStatus[] => machinery.value ? machinery.value.previousStatuses as MachineryStatus[] : [])
const currentStatus = computed((): MachineryStatus => machinery.value ? machinery.value.status as MachineryStatus : 'creation')
const didReceptionAlreadyHappen = computed(() => statusHistory.value.includes('reception') || currentStatus.value === 'reception')

const hasOverlappingInternalReservations = computed(() => (isFreelancer.value || isPartner.value) && overlappingInternalReservations.value?.length)

const update = useMutation({
  mutationFn: (machinery: ApiMachineryGetById) => {
    return $trpc.machinery.update.mutate({
      ...machinery,
      // TODO: Once we switch to local postgres for development we can use prisma enums which compile to TS-enums
      category: machinery.category as MachineryCategories,
      machineryRubric: machinery.machineryRubric as MachineryRubrics,
      purchasing: machinery.purchasing as MachineryPurchasing,
      insuranceDeductible: machinery.insuranceDeductible as MachineryInsuranceDeductible,
      status: machinery.status as MachineryStatus,
    })
  },
  onError: makeTrpcErrorToast(notification, { description: 'Die Maschine konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['machines'] })
    // TODO: Figure out a better way to not have to close all popups manually
    closeDeliveryPopup()
    closeConfirmPopup()
    closeMachineryDocumentsPopup()
    closeSalePricePopup()
    closeUVVPopup()
    closeInspectionDatePopup()
    closeLicensePlatePopup()
    closeMachineryEquipmentPopup()
    notification.success({ title: 'Die Maschine wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const setMachineryTransportFiles = useMutation({
  mutationFn: $trpc.machinery.setMachineryTransportFiles.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Transportpapiere konnten nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['machines'] })
    // TODO: Figure out a better way to not have to close all popups manually
    closeDeliveryPopup()
    closeConfirmPopup()
    notification.success({ title: 'Die Transportpapiere wurden erfolgreich aktualisiert', duration: 4500 })
  },
})

const deleteOne = useMutation({
  mutationFn: $trpc.machinery.delete.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Maschine konnte nicht gelöscht werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['machines'] })
    notification.success({ title: 'Die Maschine wurde erfolgreich gelöscht', duration: 4500 })
    navigateTo('/overview/machinery')
  },
})

const updateReceptionMachinery = useMutation({
  mutationFn: $trpc.receptionMachinery.updateOperatingHours.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Betriebsstunden konnten nicht gespeichert werden.' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['receptionMachines'] }),
    ])
    closeMachineryOperatingHoursUpdatePopup()
    notification.success({ title: 'Die Betriebsstunden wurden erfolgreich gespeichert.', duration: 4500 })
  },
})

const updateMachineryType = useMutation({
  mutationFn: $trpc.machineryType.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Machinen-Typ konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['machines'] })
    closeMachineryTypePopup()
    notification.success({ title: 'Der Machinen-Typ wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const connectMachineryAccessoryToMachinery = useMutation({
  mutationFn: (data: { machineryId: string, machineryAccessoryIds: string[] }) => $trpc.machinery.attachMachineryAccessories.mutate({ id: data.machineryId, machineryAccessoryIds: data.machineryAccessoryIds }),
  onError: makeTrpcErrorToast(notification, { description: 'Das Anbaugerät konnte nicht verbunden werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['accessories'] }),
      queryClient.invalidateQueries({ queryKey: ['shopping'] }),
    ])
    closeConnectpopup()
    notification.success({ title: 'Das Anbaugerät wurde erfolgreich verbunden', duration: 4500 })
  },
})

const createQuickSale = useMutation({
  mutationFn: $trpc.quickSale.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Das Schnellangebot konnte nicht erstellt werden' }),
  onSuccess: async (quickSale) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['quickSales'] }),
    ])
    closeQuickSaleCreationPopup()
    window.open(`/api/pdf/quickSale/details/${quickSale.id}`, '_blank')
    notification.success({ title: 'Das Schnellangebot wurde erfolgreich erstellt', duration: 4500 })
  },
})

const repurchaseMachinery = useMutation({
  mutationFn: $trpc.machinery.repurchase.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Rückkauf konnte nicht durchgeführt werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
    ])
    closeMachineryRepurchasePopup()
    notification.success({ title: 'Der Rückkauf wurde erfolgreich durchgeführt', duration: 4500 })
  },
})

const { connectedMachineryAccessories: { columns: connectedMachineryAccessoryColumns, rowProps }, receptionMachineryHistoryForOverview: { columns: receptionMachineryHistoryOverviewColumns } } = useTableColumnConfigs()
const { machineryBaseInformation, machineryType, machineryPrice, machinerySalePrice, machineryRelease, storageLocationColumn, lastUVVDate: machineryUVV, machineryInspectionDate } = useOneDataColumnConfigs()

const showReceptionProtocolEditPopup = ref<boolean>(false)

const { payload: deliveryPayload, open: openDeliveryPopup, close: closeDeliveryPopup } = usePopup<ApiMachineryGetById>()
const { payload: machineryTypePayload, open: openPrivateMachineryTypePopup, close: closeMachineryTypePopup } = usePopup<UpdateMachineryType>()
const { payload: machineryDocumentsPayload, open: openMachineryDocumentsPopup, close: closeMachineryDocumentsPopup } = usePopup<ApiMachineryGetById>()
const { payload: salePricePayload, open: openSalePricePopup, close: closeSalePricePopup } = usePopup<ApiMachineryGetById>()
const { payload: confirmPayload, open: openConfirmPopup, close: closeConfirmPopup } = usePopup<{ machineryId: string }>()
const { payload: machineryOperatingHoursPayload, open: openMachineryOperatingHoursEditPopup, close: closeMachineryOperatingHoursUpdatePopup } = usePopup<UpdateMachineryOperationHours>()
const { payload: connectPayload, open: openConnectPopup, close: closeConnectpopup } = usePopup<ApiMachineryGetById>()
const { payload: quickSalePayload, open: openQuickSaleCreationPopup, close: closeQuickSaleCreationPopup } = usePopup<CreateQuickSale>()
const { payload: machineryRepurchasePayload, open: openMachineryRepurchasePopup, close: closeMachineryRepurchasePopup } = usePopup<{ offerPositionId: string, repurchasePrice: null, repurchasedAt: null }>()
const { payload: uvvPayload, open: openUVVPopup, close: closeUVVPopup } = usePopup<ApiMachineryGetById>()
const { payload: inspectionDatePayload, open: openInspectionDatePopup, close: closeInspectionDatePopup } = usePopup<ApiMachineryGetById>()
const { payload: licensePlatePayload, open: openLicensePlatePopup, close: closeLicensePlatePopup } = usePopup<ApiMachineryGetById>()
const { payload: machineryEquipmentPayload, open: openMachineryEquipmentPopup, close: closeMachineryEquipmentPopup } = usePopup<ApiMachineryGetById>()
const popupConfirmDeletion = ref<null | { id: string }>(null)

const menuOptions: MenuOption[] = [
  {
    label: () => h(
      'a',
      {
        onClick: () => {
          activeMenu.value = 'all'
        },
      },
      'Allgemeine Informationen',
    ),
    key: 'all',
    icon: () => useRenderIcon({ name: 'material-symbols:ballot' }),
  },
]

if (isRole('project-manager')) {
  menuOptions.push({
    label: () => h(
      'a',
      {
        onClick: () => {
          activeMenu.value = 'price'
        },
      },
      'Preis Informationen',
    ),
    key: 'price',
    icon: () => useRenderIcon({ name: 'material-symbols:euro' }),
  })
}

const isInternalReservationRole = computed(() => isRole(internalReservationRoles))

const isMachinerySold = computed(() => isDateBeforeOrOnToday(machinery.value?.soldAt))
const { data: soldMachineryPosition, isFetching: isFetchingSoldMachineryPosition } = queryOfferPosition.soldMachineryPosition(props.machineryId, machinery.value?.soldAt, isMachinerySold)

const { copy: copyToClipboard, isSupported: isCopyToClipboardSupported } = useClipboard()
const showCopyMachineryDataButton = computed(() =>
  isCopyToClipboardSupported.value
  && isRole(['admin', 'department-lead', 'sales-employee', 'rental-employee', 'freelancer-sales', 'partner-sales', 'marketing-employee']),
)

const machineryDataCopyTranslations = {
  de: {
    product: 'Fabrikat',
    type: 'Typ',
    yearBuilt: 'Baujahr',
    operatingHours: 'Betriebsstunden',
    liftingWeight: 'Tragkraft (kg)',
    mast: 'Mast',
    liftingHeightInMillimeters: 'Hubhöhe (mm)',
    workHeightInMillimeters: 'Arbeitshöhe (mm)',
    overallHeightInMillimeters: 'Bauhöhe (mm)',
    forMoreInformation: 'Für mehr Informationen',
    warningTitle: 'Maschinendaten kopieren nicht möglich',
    warningContent: 'Eine Maschine muss erst aufgenommen werden, um die relevanten Informationen kopieren zu können!',
    successTitle: 'Maschinendaten kopiert',
    successContent: 'Die folgenden Maschinendaten wurden in die Zwischenablage kopiert',
  },
  en: {
    product: 'Product',
    type: 'Type',
    yearBuilt: 'Year built',
    operatingHours: 'Operating hours',
    liftingWeight: 'Lifting weight (kg)',
    mast: 'Mast',
    liftingHeightInMillimeters: 'Lifting height (mm)',
    workHeightInMillimeters: 'Work height (mm)',
    overallHeightInMillimeters: 'Overall height (mm)',
    forMoreInformation: 'For more information',
    warningTitle: 'Copying machinery data is not possible',
    warningContent: 'Machinery must be received first before copying the relevant information.',
    successTitle: 'Machinery data copied',
    successContent: 'Following machinery data copied to clipboard',
  },
}

const labelValueAsBulletPoint = (label: string, value: string | number | null | undefined) => ` - ${label}: ${String(value ?? 'N/A')}`
function copyMachineryData(lang: 'de' | 'en') {
  const receptionMachineryValue = receptionMachinery.value

  if (!receptionMachineryValue) {
    notification.warning({ title: machineryDataCopyTranslations[lang].warningTitle, content: machineryDataCopyTranslations[lang].warningContent, duration: 6500 })
    return
  }

  const isStage = machinery.value?.category === 'platform'

  const copyText = [
    `${props.machineryId}`,
    labelValueAsBulletPoint(machineryDataCopyTranslations[lang].product, receptionMachineryValue.product),
    labelValueAsBulletPoint(machineryDataCopyTranslations[lang].type, receptionMachineryValue.type?.name),
    labelValueAsBulletPoint(machineryDataCopyTranslations[lang].yearBuilt, receptionMachineryValue.yearBuilt),
    labelValueAsBulletPoint(machineryDataCopyTranslations[lang].operatingHours, receptionMachineryValue.operatingHours ? receptionMachineryValue.operatingHours + 50 : 'N/A'),
    labelValueAsBulletPoint(machineryDataCopyTranslations[lang].liftingWeight, receptionMachineryValue.liftingWeight),
    isStage ? null : labelValueAsBulletPoint(machineryDataCopyTranslations[lang].mast, receptionMachineryValue.mast ? $t(`machinery.mast.${receptionMachineryValue.mast}`) : $t('general.notApplicable')),
    isStage ? null : labelValueAsBulletPoint(machineryDataCopyTranslations[lang].liftingHeightInMillimeters, receptionMachineryValue.liftingHeightInMillimeters),
    isStage ? labelValueAsBulletPoint(machineryDataCopyTranslations[lang].workHeightInMillimeters, receptionMachineryValue.workHeightInMillimeters) : null,
    labelValueAsBulletPoint(machineryDataCopyTranslations[lang].overallHeightInMillimeters, receptionMachineryValue.overallHeightInMillimeters ?? receptionMachineryValue.mastOverallHeightInMillimeters),
    '',
    `${machineryDataCopyTranslations[lang].forMoreInformation}: ${useFullCurrentUrl('onlyOrigin')}/machinery/${props.machineryId}`,
  ].filter(s => s !== null).join('\n')

  copyToClipboard(copyText)
  notification.success({ title: machineryDataCopyTranslations[lang].successTitle, content: `${machineryDataCopyTranslations[lang].successContent}:\n\n${copyText}`, duration: 4500 })
}

const copyMachineryDataOptions = [
  {
    label: 'EN',
    key: 'en',
    props: {
      onClick: () => {
        copyMachineryData('en')
      },
    },
  },
  {
    label: 'DE',
    key: 'de',
    props: {
      onClick: () => {
        copyMachineryData('de')
      },
    },
  },
]

function connect(machineryId?: string, machineryAccessoryIds: string[] = []) {
  if (!machineryId) {
    return
  }

  connectMachineryAccessoryToMachinery.mutate({ machineryId, machineryAccessoryIds })
}

const isMachineryApprovedForSales = computed(() => machinery.value?.status === 'approval' && machinery.value?.isReleasedForSale)
const canSeeFreelancerSalesButton = computed(() => isRole(['admin', 'department-lead', 'sales-employee']) || (isRole(['freelancer-sales', 'partner-sales']) && isReleasedForCurrentUser.value))
const showQuickSalesButton = computed(() => isMachineryApprovedForSales.value && (canSeeFreelancerSalesButton.value || isRole('rental-employee')))
const isFreelancer = computed(() => isRole('freelancer-sales', false))

const freelancerSaleCreationMode = computed((): CalendarPageOfferCreationModeEmit | undefined => {
  if (!canSeeFreelancerSalesButton.value || !machinery.value) {
    return undefined
  }

  return {
    machinery: {
      id: machinery.value.id,
      producerCompanyName: machinery.value.producerCompanyName,
      type: { name: machinery.value.type.name },
      operatingHours: receptionMachinery.value?.operatingHours ?? 0,
    },
    cartPositions: [],
    itemSets: [],
    selectedStartTime: new Date(),
    rentalDuration: 0,
    isAccessoryOnlyOffer: false,
  }
})

const offerTypeMachineryCurrentlyIn = computed(() => {
  const relatedOfferPosition = machinery.value?.relatedOfferPositions.find(position => !position.relatedOffer.isCancelled && ['rental', 'service-project'].includes(position.relatedOffer.type) && position.isLoaded && !position.isReturned)

  return relatedOfferPosition?.relatedOffer.type
})

// permissions
const canSeeReleaseDevice = computed(() => canEditReleaseDevice.value || isRole(['project-manager', 'disponent', 'workshop-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter', 'logistics-employee']))
const canEditReleaseDevice = computed(() => isRole(['rental-employee', 'sales-employee', 'department-lead']))
const canSeeInternalReservation = computed(() => isRole(['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter', 'logistics-employee']))
const canSeeReceptionMachineryForm = computed(() => canEditReceptionMachineryForm.value || isRole(['rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'bookkeeping', 'storage-employee', 'freelancer-sales', 'guest', 'partner-sales', 'logistics-employee', 'receptionist']))
const canEditReceptionMachineryForm = computed(() => {
  const rolesToCheck: UserRole[] = ['project-manager', 'department-lead', 'consignee', 'special-role-benni', 'storage-lead', 'service-project-employee', 'service-project-fitter']
  if (isForeignMachinery.value) {
    rolesToCheck.push('workshop-employee')
  }

  return isRole(rolesToCheck)
})
const canSeeSalesPriceInfo = computed(() => canEditSalePriceInfo.value || isRole(['project-manager', 'department-lead', 'sales-employee', 'freelancer-sales', 'partner-sales']))
const canEditSalePriceInfo = computed(() => isRole('admin'))
const canEditMachineryType = computed(() => isRole(['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter']))
const canSeeDocuments = computed(() => canEditDocuments.value || isRole(['driver', 'bookkeeping', 'freelancer-sales', 'partner-sales', 'logistics-employee']))
const canEditDocuments = computed(() => isRole(['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter']))
const canSeeDefects = computed(() => canEditDefects.value || isRole(['bookkeeping', 'freelancer-sales', 'partner-sales', 'logistics-employee']))
const canEditDefects = computed(() => isRole(['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter']))
const canSeeKeyPoints = computed(() => canEditKeyPoints.value || isRole(['department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter', 'freelancer-sales', 'guest', 'partner-sales', 'logistics-employee']))
const canEditKeyPoints = computed(() => isRole(['project-manager']) || (isRole(['workshop-employee']) && isForeignMachinery.value))
const canSeeStorageLocation = computed(() => canEditStorageLocation.value || isRole(['project-manager', 'department-lead', 'service-project-employee', 'service-project-fitter', 'logistics-employee']))
const canEditStorageLocation = computed(() => isRole(['rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni']))
const canSeeQRCode = computed(() => isRole(['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter', 'freelancer-sales', 'partner-sales', 'logistics-employee']))
const canSeeTransportFiles = computed(() => canEditTransportFiles.value || isRole(['logistics-employee']))
const canEditTransportFiles = computed(() => isRole(['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter']))
const canSeeOperatingHours = computed(() => canEditOperatingHours.value || isRole(['project-manager', 'department-lead', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'bookkeeping', 'storage-lead', 'storage-employee', 'freelancer-sales', 'partner-sales', 'logistics-employee']))
const canEditOperatingHours = computed(() => isRole(['consignee', 'special-role-benni']))
const canEditInternalCost = computed(() => isRole(['workshop-employee']))
const canEditUVV = computed(() => isRole(['department-lead', 'workshop-employee', 'special-role-benni', 'receptionist']))
const canEditMachineryEquipment = computed(() => isRole(['marketing-employee', 'project-manager']))

const canSeeInspectionDate = computed(() => ['tractor', 'trailer'].includes(machineryCategory.value))
const canSeeLicensePlate = computed(() => ['tractor', 'trailer'].includes(machineryCategory.value))

const {
  openDefectCreateOrUpdatePopup,
  openMachineryCreateOrEditPopup,
  openReceptionProtocol,
  openReceptionProtocolHistory,
  openOfferPage,
  openInternalReservationCreateOrUpdatePopup,
  openLogisticsStorageLocationPopup,
  openIssuanceUpdateNotReturnablePopup,
} = useGlobalOpeners()

const router = useRouter()

function getOfferUrl(offerId: string) {
  return withQuery('/offer/edit', {
    'popupOffer[mode]': 'edit',
    'popupOffer[id]': offerId,
  })
}

const { machineryFieldsByMachineType: showMachineryFieldsByMachineType } = useFormOptionalConditions()

const machineryKeyPoints: OneDataColumn<ApiMachineryGetById>[] = [
  {
    title: 'Kategorie',
    key: 'category',
    render: ({ category }) => $t(`machinery.category.${category}`),
    minWidth: 140,
  },
  {
    title: 'Lieferant',
    key: 'deliveryCompanyName',
    isVisible: () => !isFreelancer.value,
  },
  {
    title: 'Hersteller',
    key: 'producerCompanyName',
  },
  {
    title: 'Typ',
    key: 'type',
    render: ({ type }) => type.name,
  },
  {
    title: 'Rubrik',
    key: 'machineryRubric',
    render: ({ machineryRubric }) => $t(`machinery.rubric.${machineryRubric}`),
  },
  {
    title: 'Seriennummer',
    key: 'serialNumber',
  },
  {
    title: 'Hubhöhe (mm)',
    key: 'liftingHeightInMillimeters',
    isVisible: ({ category }) => showMachineryFieldsByMachineType.liftingHeightInMillimeters.includes(category as MachineryCategories),
  },
  {
    title: 'Tragkraft (kg)',
    key: 'liftingWeight',
    render: ({ liftingWeight }) => liftingWeight.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryFieldsByMachineType.liftingWeight.includes(category as MachineryCategories),
  },
  {
    title: 'Arbeitshöhe (mm)',
    key: 'workHeightInMillimeters',
    isVisible: ({ category }) => showMachineryFieldsByMachineType.workHeightInMillimeters.includes(category as MachineryCategories),
  },
  {
    title: 'Lieferdatum',
    key: 'deliveryDate',
    render: ({ deliveryDate }) => deliveryDate ? useDateAsString(deliveryDate, 'dd.MM.yy') : 'N/A',
  },
  {
    title: 'Gabellänge (mm)',
    key: 'forkLengthInMillimeters',
    isVisible: ({ category }) => showMachineryFieldsByMachineType.forkLengthInMillimeters.includes(category as MachineryCategories),
  },
  {
    title: 'Beschaffung',
    key: 'purchasing',
  },
  {
    title: 'Notiz',
    key: 'comment',
  },
  {
    title: 'Kennzeichen',
    key: 'licensePlateNumber',
    isVisible: ({ category }) => showMachineryFieldsByMachineType.licensePlateNumber.includes(category as MachineryCategories),
  },
  {
    title: 'Antrieb',
    key: 'drive',
    render: ({ drive }) => machineryDrivesToGerman[drive.name as MachineryDrives],
    isVisible: ({ category }) => category === 'tractor',
  },
]
</script>

<template>
  <div>
    <ThePopup v-if="deliveryPayload" :show="Boolean(deliveryPayload)" :title="`Transportpapiere Gerät ${deliveryPayload.id} (${deliveryPayload.type.name})`" @close="closeDeliveryPopup">
      <MachineryCreationTransportationPaperUploadForm mode="edit" :payload="deliveryPayload" @save="data => setMachineryTransportFiles.mutate({ ...data, status: data.status as MachineryStatus })" />
    </ThePopup>
    <ThePopup v-if="machineryTypePayload" :show="Boolean(machineryTypePayload)" title="Gerätetyp-Anlage" @close="closeMachineryTypePopup">
      <MachineryTypeCreationCreateOrEditForm :payload="machineryTypePayload" :machinery-types="machineryTypes ?? []" @save="(data) => updateMachineryType.mutateAsync(data.data as UpdateMachineryType['data'])" />
    </ThePopup>
    <ThePopup v-if="machineryDocumentsPayload" :show="Boolean(machineryDocumentsPayload)" title="Maschinen-Dokumente" @close="closeMachineryDocumentsPopup">
      <MachineryDocumentsForm
        :payload="machineryDocumentsPayload" @save="update.mutate"
      />
    </ThePopup>
    <ThePopup v-if="confirmPayload" :show="Boolean(confirmPayload)" :title="`Freigabe ${confirmPayload.machineryId} (${machinery?.type.name ?? 'Typ lädt...'})`" @close="closeConfirmPopup">
      <MachineryCreationUpdateToConfirmForm :payload="confirmPayload" :is-details-page="true" @save="update.mutate" />
    </ThePopup>
    <ThePopup v-if="machineryOperatingHoursPayload" :show="Boolean(machineryOperatingHoursPayload)" title="Betriebsstunden anpassen" @close="closeMachineryOperatingHoursUpdatePopup">
      <MachineryEditOperatingHours :payload="machineryOperatingHoursPayload" :is-loading-reception-machinery="isLoadingReceptionMachinery" :is-details-page="true" @save="(data) => updateReceptionMachinery.mutate(data)" />
    </ThePopup>
    <ThePopup v-if="connectPayload" :show="Boolean(connectPayload)" width="90%" @close="closeConnectpopup">
      <ServiceMachineryAccessoryConnectionsOfMachinery
        v-if="connectPayload"
        :machinery-id="connectPayload.id"
        :machinery-category="connectPayload.category as MachineryCategories"
        :already-checked-row-keys="connectPayload.attachedMachineryAccessories.map(accessory => accessory.id)"
        :compatible-machinery-type-id="connectPayload.typeId"
        @connect="machineryAccessoryIds => connect(connectPayload?.id, machineryAccessoryIds)"
      />
    </ThePopup>
    <ThePopup v-if="quickSalePayload" :show="Boolean(quickSalePayload)" title="Schnellangebot erstellen" @close="closeQuickSaleCreationPopup">
      <QuickSaleCreationForm :payload="quickSalePayload" @save="createQuickSale.mutate" />
    </ThePopup>
    <ThePopup v-if="salePricePayload" :show="Boolean(salePricePayload)" title="Verkaufspreis-Informationen aktualisieren" @close="closeSalePricePopup">
      <MachinerySalePriceForm :payload="salePricePayload" @save="data => update.mutate(data)" />
    </ThePopup>
    <ThePopup v-if="machineryRepurchasePayload" :show="Boolean(machineryRepurchasePayload)" :title="`Rückkauf ${machineryId}`" @close="closeMachineryRepurchasePopup">
      <MachineryRepurchaseForm :payload="machineryRepurchasePayload" @save="(data) => repurchaseMachinery.mutate({ ...data, machineryId })" />
    </ThePopup>
    <ThePopup v-if="uvvPayload" :show="Boolean(uvvPayload)" title="UVV" @close="closeUVVPopup">
      <MachineryUVVForm :payload="uvvPayload" @save="update.mutate" />
    </ThePopup>
    <ThePopup v-if="inspectionDatePayload" :show="Boolean(inspectionDatePayload)" title="TÜV / SP / Tacho / Sondergenehmigung" @close="closeInspectionDatePopup">
      <MachineryInspectionDateForm :payload="inspectionDatePayload" @save-machinery="update.mutate" />
    </ThePopup>
    <ThePopup v-if="licensePlatePayload" :show="Boolean(licensePlatePayload)" title="Kennzeichen anpassen" @close="closeLicensePlatePopup">
      <MachineryEditLicensePlate :payload="licensePlatePayload" @save="update.mutate" />
    </ThePopup>
    <ThePopup v-if="machineryEquipmentPayload" :show="Boolean(machineryEquipmentPayload)" title="Maschinen Ausstattung" @close="closeMachineryEquipmentPopup">
      <MachineryEquipmentForm :payload="machineryEquipmentPayload" @save="update.mutate" />
    </ThePopup>

    <MachineryCreationReceptionProtocolEditPopup :show="showReceptionProtocolEditPopup" :machinery-id="machineryId" @close="showReceptionProtocolEditPopup = false" />

    <TheConfirmPopup v-if="popupConfirmDeletion" @confirm="deleteOne.mutate(popupConfirmDeletion)" @close="popupConfirmDeletion = null">
      Maschine {{ popupConfirmDeletion.id }} wird gelöscht. Alle bisher gespeicherten Informationen zu dieser Maschine gehen verloren.
    </TheConfirmPopup>

    <div class="flex flex-col md:flex-row items-center gap-2">
      <n-menu v-model:value="activeMenu" mode="horizontal" :options="menuOptions" class="bg-white w-full" />

      <n-dropdown v-if="showCopyMachineryDataButton" trigger="click" :options="copyMachineryDataOptions">
        <n-button class="w-full md:w-auto">
          Maschinendaten kopieren
        </n-button>
      </n-dropdown>

      <n-button v-if="showQuickSalesButton" class="w-full md:w-auto" @click="openQuickSaleCreationPopup({ machineryId, description: '', includeVAT: true })">
        Schnellangebot erstellen
      </n-button>
    </div>

    <div class="space-y-2 mb-2">
      <n-alert v-if="isMachinerySold" type="warning">
        <div class="flex items center justify-between gap-2">
          <p>
            Verkauft: Das Gerät wurde am {{ machinery?.soldAt ? useDateAsString(machinery.soldAt, 'dd.MM.yy') : 'N/A' }}
            <span v-if="!isFetchingSoldMachineryPosition && soldMachineryPosition">an den Kunden {{ soldMachineryPosition.relatedOffer.customer.name }}</span>
            verkauft
            <NuxtLink v-if="!isFetchingSoldMachineryPosition && soldMachineryPosition" :to="getOfferUrl(soldMachineryPosition.relatedOffer.id)" class="hover:text-primary underline">
              ({{ soldMachineryPosition.relatedOffer.id }})
            </NuxtLink>
          </p>
          <n-button v-if="!isFetchingSoldMachineryPosition && soldMachineryPosition" @click="openMachineryRepurchasePopup({ offerPositionId: soldMachineryPosition.id, repurchasePrice: null, repurchasedAt: null })">
            Rückkauf anlegen
          </n-button>
        </div>
      </n-alert>
      <n-alert
        v-if="machinery?.isPermanentlyNotAvailable && notReturnableOfferPositions?.length"
        class="mb-2"
        type="warning"
      >
        Das Gerät ist dauerhaft nicht verfügbar ({{ machinery.notAvailableReason ?? 'N/A' }})
        <p>
          Basierend auf <span
            class="underline cursor-pointer text-primary"
            @click="openOfferPage({ mode: 'edit', id: notReturnableOfferPositions[0].relatedOffer.id, redirectUrl: router.currentRoute.value.fullPath })"
          >{{ notReturnableOfferPositions[0].relatedOffer.id }}</span>
        </p>
        <n-button
          class="!bg-white mt-2"
          @click="openIssuanceUpdateNotReturnablePopup.open({ id: notReturnableOfferPositions[0].id, shouldOpenReturnPopup: true })"
        >
          Rücknahme jetzt durchführen
        </n-button>
      </n-alert>
      <n-alert v-if="machinery?.isDefective" type="warning">
        Defekt: Das Gerät hat zurzeit {{ machinery?.defects.filter(d => d.priority === 0).length ?? '0' }} unaufschiebbare Defekt(e).
      </n-alert>
      <n-alert v-if="hasOverlappingInternalReservations" type="warning">
        Bitte beachte, die Maschine befindet sich gerade in einer internen Reservierung.
      </n-alert>
      <n-alert v-if="(isFreelancer || isPartner) && offerTypeMachineryCurrentlyIn" type="warning">
        Bitte beachte, die Maschine ist aktuell nicht verfügbar.
      </n-alert>
      <n-alert v-if="machinery?.isForeignMachinery" type="info">
        Bitte beachte: Bei diesem Gerät handelt es sich um ein Fremdgerät des Kunden: {{ machinery.foreignMachineryCustomer?.name ?? 'Unbekannt' }}.
      </n-alert>

      <FreelancerSalesReleaseConditionBanner v-if="isFreelancer && machinery && freelancerSaleCreationMode" :machinery="machinery" :freelancer-sale-creation-mode="freelancerSaleCreationMode" />
      <PartnerSalesReleaseConditionBanner v-if="isPartner && machinery" :machinery="machinery" />
    </div>

    <TheDataCard v-if="isRole('admin') && currentStatus === 'approval' && !isForeignMachinery" class="my-2">
      <n-collapse>
        <n-collapse-item>
          <template #header>
            <span class="text-lg">
              Umsatz-Übersicht
            </span>
          </template>
          <ReportingCardMachineryRevenue :machinery-id="machineryId" />
        </n-collapse-item>
      </n-collapse>
    </TheDataCard>

    <TheDataCard v-if="activeMenu === 'all' && canAccessPage('/calendar') && !isForeignMachinery" class="my-2">
      <n-collapse>
        <n-collapse-item>
          <template #header>
            <span class="text-lg">
              Kalender, Angebots- und Auftrags-Übersicht ausklappen
            </span>
          </template>
          <CalendarPage :additional-filters-machinery="{ id: props.machineryId }" show-calendar-for="machinery" :allow-offer-creation-mode="false" :show-machinery-filters="false" />
          <OfferPage class="my-2" view="all" :additional-filters="{ positions: { some: { machineryId: props.machineryId } } }" :initial-page-size="2" />
        </n-collapse-item>
      </n-collapse>
    </TheDataCard>

    <TheDataCard v-if="activeMenu === 'all' && canAccessPage('/invoice') && !isForeignMachinery" class="my-2">
      <n-collapse>
        <n-collapse-item>
          <template #header>
            <span class="text-lg">
              Rechnungs-Übersicht ausklappen
            </span>
          </template>
          <InvoicePage
            v-if="machinery"
            title=""
            :additional-filters="{ offerPositions: { some: { offerPosition: { machineryId: machinery.id } } }, type: { not: { in: ['cancellation', 'creditNoteCancellation'] } } }"
            :show-color-coding="true"
          />
        </n-collapse-item>
      </n-collapse>
    </TheDataCard>

    <TheDataCard v-if="activeMenu === 'all' && canSeeFreelancerSalesButton && !isForeignMachinery" class="my-2">
      <n-collapse>
        <n-collapse-item>
          <template #header>
            <span class="text-lg">
              Schnellangebote ausklappen
            </span>
          </template>
          <QuickSaleOverviewTable :machinery-id="machineryId" />
        </n-collapse-item>
      </n-collapse>
    </TheDataCard>

    <TheDataCard v-if="activeMenu === 'all' && (canAccessPage('/invoice') || canEditInternalCost) && !isForeignMachinery" class="my-2">
      <n-collapse>
        <n-collapse-item>
          <template #header>
            <span class="text-lg">
              Interne Kosten-Übersicht ausklappen
            </span>
          </template>
          <InternalCostPage v-if="machinery" :id="machinery.id" type="machinery" />
        </n-collapse-item>
      </n-collapse>
    </TheDataCard>

    <MachineryDetailsPagePartner v-if="isPartner" :machinery-id="props.machineryId" />

    <div v-else class="flex gap-2 flex-wrap">
      <div class="w-full flex gap-2 flex-col DetailsColumn">
        <TheDataCard :error="errorMachinery" :is-loading="isLoadingMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>Geräteübersicht</h2>
            </div>
          </template>
          <TableOneDataColumn :config="{ columns: machineryBaseInformation, data: machinery }" />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all' && canSeeReleaseDevice && !isForeignMachinery" :error="errorMachinery" :is-loading="isLoadingMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>Gerätefreigabe</h2>
              <n-button
                v-if="!isUpdateDisabled && canEditReleaseDevice && currentStatus === 'approval'"
                @click="openConfirmPopup({ machineryId })"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <div class="flex flex-col pb-2">
            <span v-if="!!machinery && machinery.releasedStateUpdatedAt && machinery.releasedStateUpdatedBy">
              Gerätefreigabe zuletzt geändert von: {{ machinery.releasedStateUpdatedBy.name }} ({{ machinery.releasedStateUpdatedBy.email }})({{ useDateAsString(machinery.releasedStateUpdatedAt, 'dd.MM.yyyy') }})
            </span>
          </div>
          <p v-if="currentStatus !== 'approval'" class="mb-2">
            <span class="font-bold">Hinweis: </span>Diese Werte können erst bearbeitet werden, wenn das Gerät die Aufnahme komplett durchlaufen hat.
          </p>
          <TableOneDataColumn :config="{ columns: machineryRelease, data: machinery }" />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all' && canSeeInternalReservation && !isForeignMachinery" :error="errorMachinery" :is-loading="isLoadingMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>Interne Reservierung</h2>
              <n-button
                v-if="!isUpdateDisabled && currentStatus === 'approval' && isInternalReservationRole"
                @click="openInternalReservationCreateOrUpdatePopup.open({ machineryId, mode: 'create' })"
              >
                <template #icon>
                  <Icon name="material-symbols:add-circle-outline" />
                </template>Interne Reservierung erstellen
              </n-button>
            </div>
          </template>
          <InternalReservationOverview :additional-filters="{ machineryId }" />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all'" :error="errorMachinery" :is-loading="isLoadingMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>Gerätetyp</h2>
              <n-button v-if="!isUpdateDisabled && canEditMachineryType" @click="openPrivateMachineryTypePopup({ mode: 'update', data: machinery?.type } as UpdateMachineryType)">
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <TableOneDataColumn v-if="machinery" :config="{ columns: machineryType, data: machinery.type }" />

          <FileView class="mt-2" label="Datenblatt (deutsch/englisch)" wrap-path-in-object :files="machinery?.type.documentDataSheetFiles ?? []" collapsed-by-default />
          <FileView label="Bedienungsanleitung" wrap-path-in-object :files="machinery?.type.documentManualFiles ?? []" collapsed-by-default />
          <FileView label="Videos" wrap-path-in-object :files="machinery?.type.documentVideoFiles ?? []" collapsed-by-default />
          <FileView :label="$t('machinery.detailsPage.homepageImages')" wrap-path-in-object :files="machinery?.type.homepageImages ?? []" collapsed-by-default />
          <FileView label="Externe Videos" :files="machinery?.type.documentExternalVideoFiles ?? []" collapsed-by-default />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all' && canSeeDocuments" :error="errorMachinery" :is-loading="isLoadingMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>Dokumente</h2>
              <n-button v-if="!isUpdateDisabled && canEditDocuments" @click="openMachineryDocumentsPopup(machinery as ApiMachineryGetById)">
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>

          <FileView label="CE" :files="machinery?.documentCEFiles ?? []" collapsed-by-default />
          <FileView label="Ersatzteilkatalog" :files="machinery?.documentSparePartsCatalogFiles ?? []" collapsed-by-default />
          <FileView label="Schaltpläne" :files="machinery?.documentCircuitDiagramsFiles ?? []" collapsed-by-default />
          <FileView label="Gewichtstest" :files="machinery?.documentWeightTest ?? []" collapsed-by-default />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all' && canSeeDefects" :is-loading="isLoadingReceptionMachinery" :error="errorMachinery || errorReceptionMachinery">
          <template #header>
            <div class="CardHeader flex-col !items-start md:flex-row">
              <h2>Gerätezustand ({{ machinery?.defects.length ?? 0 }} Defekt(e))</h2>
              <n-button v-if="!isUpdateDisabled && canEditDefects" class="w-full md:w-auto" @click="openDefectCreateOrUpdatePopup.open({ itemId: machineryId, type: 'machinery', mode: 'create' })">
                <template #icon>
                  <Icon name="material-symbols:add-circle-outline" />
                </template>Defekt erstellen
              </n-button>
            </div>
          </template>
          <p class="mb-3">
            Hier sehen Sie die Defekte der Maschine.
          </p>

          <DefectPage :additional-filters="{ machineryId }" overview-title="" />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all' && canSeeReceptionMachineryForm" :is-loading="isLoadingReceptionMachinery" :error="errorMachinery || errorReceptionMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>Aufnahme Informationen</h2>

              <n-button
                v-if="machinery?.isForeignMachinery && !receptionMachinery"
                @click="openReceptionProtocol.open({
                  mode: 'create',
                  machineryId: machinery.id,
                  typeId: machinery.typeId,
                  category: machinery.category as MachineryCategories,
                  drive: machinery.drive.name as MachineryDrives,
                })"
              >
                <template #icon>
                  <Icon name="material-symbols:add-circle-outline" />
                </template>Erstellen
              </n-button>

              <n-button
                v-if="
                  !isUpdateDisabled
                    && canEditReceptionMachineryForm
                    && (statusHistory.includes('reception') || currentStatus === 'reception')
                    && receptionMachinery && machinery"
                @click="showReceptionProtocolEditPopup = true"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>

          <div v-if="receptionMachinery" class="flex flex-col mb-4">
            <p v-if="receptionMachinery.createdAt && receptionMachinery.createdBy">
              Initiale Aufnahme durchgeführt von {{ receptionMachinery.createdBy.name ?? 'Unbekannt' }} ({{ receptionMachinery.createdBy.email }}) am {{ useDateAsString(receptionMachinery.createdAt, 'dd.MM.yyyy') }}
            </p>

            <p v-if="receptionMachinery.updatedAt && receptionMachinery.updatedBy">
              Aufnahme Informationen zuletzt geändert von {{ receptionMachinery.updatedBy.name ?? 'Unbekannt' }} ({{ receptionMachinery.updatedBy.email }}) am {{ useDateAsString(receptionMachinery.updatedAt, 'dd.MM.yyyy') }}
            </p>
          </div>

          <n-collapse v-if="!isPartner" class="mb-4">
            <n-collapse-item>
              <template #header>
                <span class="font-semibold">Modifikations-Historie ausklappen</span>
              </template>

              <TableView
                :data="receptionMachineryHistory"
                :columns="receptionMachineryHistoryOverviewColumns"
                :action-buttons="() => ['showDetails']"
                :is-loading="isLoadingReceptionMachineryHistory"
                @show-details="({ id }) => openReceptionProtocolHistory.open({ id })"
              />
            </n-collapse-item>
          </n-collapse>

          <div v-if="!didReceptionAlreadyHappen" class="h-56 flex items-center justify-center">
            <n-empty description="Es wurde noch keine Aufnahme für das Gerät durchgeführt." />
          </div>

          <MachineryReceptionTable v-else-if="receptionMachinery" :reception-machinery="receptionMachinery" />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all'" :error="errorMachinery" :is-loading="isLoadingMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>UVV</h2>
              <n-button v-if="!isUpdateDisabled && canEditUVV" @click="openUVVPopup(machinery as ApiMachineryGetById)">
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <span v-if="machinery?.lastUVVDateUpdatedAt && machinery?.lastUVVDateUpdatedBy">
            Zuletzt geändert von: {{ machinery.lastUVVDateUpdatedBy.name }} ({{ machinery.lastUVVDateUpdatedBy.email }})({{ useDateAsString(machinery.lastUVVDateUpdatedAt, 'dd.MM.yyyy') }})
          </span>
          <TableOneDataColumn :config="{ columns: machineryUVV, data: machinery }" />
          <FileView label="Dokumente" :files="machinery?.documentUVVFiles ?? []" collapsed-by-default />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all'" :error="errorMachinery" :is-loading="isLoadingMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>Maschinen Ausstattung</h2>
              <n-button v-if="!isUpdateDisabled && canEditMachineryEquipment" @click="openMachineryEquipmentPopup(machinery as ApiMachineryGetById)">
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>

          <div v-if="machinery" class="flex flex-col gap-2">
            <p class="font-semibold">
              Anbaugeräte
            </p>
            <div class="whitespace-pre text-wrap">
              {{ !machinery.marketingMachineryAccessoryDescription ? 'N/A' : machinery.marketingMachineryAccessoryDescription }}
            </div>

            <p class="font-semibold">
              Sonderausstattung
            </p>
            <div class="whitespace-pre text-wrap">
              {{ !machinery.marketingSpecialEquipmentDescription ? 'N/A' : machinery.marketingSpecialEquipmentDescription }}
            </div>
          </div>
        </TheDataCard>
      </div>

      <div class="w-full flex gap-2 flex-col DetailsColumn">
        <TheDataCard
          v-if="activeMenu === 'all' && canSeeKeyPoints"
          :is-loading="isLoadingMachinery"
          :error="errorMachinery"
        >
          <template #header>
            <div class="CardHeader">
              <h2>Key Points</h2>
              <n-button
                v-if="!isUpdateDisabled && machinery && canEditKeyPoints"
                @click="openMachineryCreateOrEditPopup.open({ mode: 'update', id: machinery.id, isForeignMachinery: machinery.isForeignMachinery })"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <TableOneDataColumn :config="{ columns: machineryKeyPoints, data: machinery }" />
        </TheDataCard>

        <TheDataCard
          v-if="activeMenu === 'all' && canSeeStorageLocation && !isForeignMachinery"
          :is-loading="isLoadingMachinery"
          :error="errorMachinery"
        >
          <template #header>
            <div class="CardHeader">
              <h2>Aktueller Lagerplatz</h2>
              <n-button
                v-if="!isUpdateDisabled && canEditStorageLocation"
                @click="openLogisticsStorageLocationPopup.open({ id: machineryId, model: 'machinery', storageLocation: machinery?.storageLocation })"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <div v-if="machinery" class="flex flex-col pb-2">
            <span v-if="machinery.storageLocationCreatedAt && machinery.storageLocationCreatedBy">
              Initialer Lagerplatz zugeordnet von: {{ machinery.storageLocationCreatedBy.name }} ({{ machinery.storageLocationCreatedBy.email }})({{ useDateAsString(machinery.storageLocationCreatedAt, 'dd.MM.yyyy') }})
            </span>
            <span v-if="machinery.storageLocationUpdatedAt && machinery.storageLocationUpdatedBy">
              Lagerplatz zuletzt geändert von: {{ machinery.storageLocationUpdatedBy.name }} ({{ machinery.storageLocationUpdatedBy.email }})({{ useDateAsString(machinery.storageLocationUpdatedAt, 'dd.MM.yyyy') }})
            </span>
          </div>
          <TableOneDataColumn :config="{ columns: storageLocationColumn, data: machinery }" />
        </TheDataCard>

        <TheDataCard v-if="!isForeignMachinery && isRole(['project-manager', 'department-lead', 'bookkeeping', 'sales-employee'])" :is-loading="isLoadingMachinery" :error="errorMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>Einkaufspreis Informationen</h2>
              <n-button
                v-if="!isUpdateDisabled && machinery && isRole(['bookkeeping'])"
                @click="openMachineryCreateOrEditPopup.open({ mode: 'update', id: machinery.id })"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <TableOneDataColumn :config="{ columns: machineryPrice, data: machinery }" />
          <FileView label="Zusatzdokumente" wrap-path-in-object :files="machinery?.documentFiles ?? []" collapsed-by-default />
        </TheDataCard>

        <TheDataCard v-if="canSeeSalesPriceInfo && !isForeignMachinery" :is-loading="isLoadingMachinery" :error="errorMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>Verkaufspreis Informationen</h2>
              <n-button
                v-if="!isUpdateDisabled && machinery && canEditSalePriceInfo"
                @click="openSalePricePopup(machinery)"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <TableOneDataColumn :config="{ columns: machinerySalePrice, data: machinery }" />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all' && isRole(['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'bookkeeping', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'])" :is-loading="isLoadingReceptionMachinery" :error="errorMachinery || errorReceptionMachinery">
          <CommentList :id="machineryId" type="machinery" list-title-text="Maschinen Kommentare" />
        </TheDataCard>

        <TheDataCard v-if="canSeeQRCode && (statusHistory.includes('marking') || currentStatus === 'marking' || isForeignMachinery)" title="QR Code" :error="errorMachinery" :is-loading="isLoadingMachinery">
          <QrCodeDownloadSelect :id="machineryId" type="machinery" />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all' && canSeeTransportFiles && !isForeignMachinery" :is-loading="isLoadingMachinery" :error="errorMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>Transportpapiere</h2>
              <n-button
                v-if="!isUpdateDisabled && canEditTransportFiles && statusHistory.includes('delivery') || currentStatus === 'delivery'"
                @click="openDeliveryPopup(machinery as ApiMachineryGetById)"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <div v-if="!statusHistory.includes('delivery') && currentStatus !== 'delivery'" class="h-56 flex items-center justify-center">
            <n-empty description="Es wurden noch keine Transportpapiere für das Gerät hochgeladen." />
          </div>
          <FileView v-else :files="machinery?.transportFiles ?? []" collapsed-by-default />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all' && !isForeignMachinery" title="Bilder" :is-loading="isLoadingMachinery">
          <FileView label="Geräte-Bilder" :files="machinery?.marketingPhotos ?? []" collapsed-by-default />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all' && !isForeignMachinery" title="Videos" :is-loading="isLoadingMachinery">
          <FileView label="Geräte-Videos" :files="machinery?.marketingVideos ?? []" collapsed-by-default />
          <FileView label="Externe Geräte-Videos" :files="machinery?.externalMarketingVideos ?? []" collapsed-by-default />
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all' && !isForeignMachinery" :is-loading="isLoadingMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>Fest verbundene Geräte</h2>
              <n-button v-if="!isUpdateDisabled && machinery && machinery.status === 'approval' && !isRole('guest', false) && (!isFreelancer || isReleasedForCurrentUser) && !isPartner" @click="openConnectPopup(machinery)">
                Verbindungen verwalten
              </n-button>
            </div>
          </template>
          <TableView
            v-if="machinery"
            :data="machinery.attachedMachineryAccessories"
            :columns="connectedMachineryAccessoryColumns"
            :is-loading="isLoadingMachinery"
            :row-props="rowProps"
          />
        </TheDataCard>

        <FreelancerSalesDataCardReleasedForSaleMachineryConditions v-if="isRole('admin') && !isForeignMachinery" :is-loading="isLoadingMachinery" :machinery-id="machineryId" />

        <PartnerSalesDataCardReleasedForSaleMachineryConditions v-if="isRole('admin') && !isForeignMachinery" :is-loading="isLoadingMachinery" :machinery-id="machineryId" />

        <MachineryCompatibleAccessoriesAndConnectCard
          v-if="activeMenu === 'all' && !isPartner && machinery && machinery?.category !== 'forklift' && !isForeignMachinery"
          :is-loading="isLoadingMachinery"
          :error="errorMachinery"
          :machinery="machinery"
          :do-load-compatible-accessories="didReceptionAlreadyHappen"
          :is-update-disabled="!!isUpdateDisabled || isRole('freelancer-sales', false)"
        />

        <TheDataCard
          v-if="activeMenu === 'all' && canSeeOperatingHours"
          :is-loading="isLoadingReceptionMachinery"
          :error="errorMachinery || errorReceptionMachinery"
        >
          <template #header>
            <div class="CardHeader">
              <h2>Aktuelle Betriebsstunden</h2>
              <n-button
                v-if="!isUpdateDisabled && canEditOperatingHours && currentStatus === 'approval'"
                @click="openMachineryOperatingHoursEditPopup(receptionMachinery as UpdateMachineryOperationHours)"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <p class="font-semibold">
            {{ receptionMachinery?.operatingHours ?? 'Keine Information' }} Stunden
            <span v-if="receptionMachinery?.operatingHoursUpdatedAt && receptionMachinery?.operatingHoursUpdatedBy" class="text-xs text-gray-500">
              (zuletzt aktualisiert von: {{ receptionMachinery?.operatingHoursUpdatedBy.name }} ({{ useDateAsString(receptionMachinery?.operatingHoursUpdatedAt) }}) ({{ receptionMachinery?.operatingHoursUpdatedBy.email }}))
            </span>
          </p>
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all' && canSeeLicensePlate" :error="errorMachinery" :is-loading="isLoadingMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>Kennzeichen</h2>
              <n-button
                @click="openLicensePlatePopup(machinery as ApiMachineryGetById)"
              >
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <p class="font-semibold">
            {{ machinery?.licensePlateNumber ?? 'N/A' }}
          </p>
        </TheDataCard>

        <TheDataCard v-if="activeMenu === 'all' && canSeeInspectionDate" :error="errorMachinery" :is-loading="isLoadingMachinery">
          <template #header>
            <div class="CardHeader">
              <h2>TÜV / SP / Tacho / Sondergenehmigung</h2>
              <n-button v-if="!isUpdateDisabled" @click="openInspectionDatePopup(machinery as ApiMachineryGetById)">
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>Ändern
              </n-button>
            </div>
          </template>
          <span v-if="machinery?.inspectionDateUpdatedAt && machinery?.inspectionDateUpdatedBy">
            Zuletzt geändert von: {{ machinery.inspectionDateUpdatedBy.name }} ({{ machinery.inspectionDateUpdatedBy.email }})({{ useDateAsString(machinery.inspectionDateUpdatedAt, 'dd.MM.yyyy') }})
          </span>
          <TableOneDataColumn :config="{ columns: machineryInspectionDate, data: machinery }" />
        </TheDataCard>
      </div>
    </div>
    <n-button
      v-if="isRole('admin') && currentStatus !== 'approval'"
      color="red"
      class="my-2 md:mb-0 md:mt-4 w-full md:w-auto"
      @click="popupConfirmDeletion = { id: machineryId }"
    >
      <Icon name="material-symbols:error-outline" class="mr-2" />
      Gerät löschen
    </n-button>
  </div>
</template>

<style scoped>
.CardHeader {
  @apply  w-full flex justify-between mb-5 items-center md:items-start
}
.CardHeader h2 {
  @apply text-lg font-semibold
}
.DetailsColumn {
  @apply md:w-[400px] flex-grow;
}
</style>
