import type { z } from 'zod'
import { machineryAccessoryTrailerTypes } from '~/server/schemas'
import { machineryAccessoryCategories } from '~/server/schemas/machineryAccessory'
import type { machineryFieldsToCompare } from '~/server/schemas'
import type { CustomerSchemaToCheckOrderAvailability, CustomerStatus, CustomerTypes, InvoicableOffersWherePreset, InvoiceStatus, InvoiceType, LogisticsConditionRating, LogisticsRentalFullStatus, LogisticsTaskType, LogisticsVisualDetailsPollution, MachineryAccessoryCategory, MachineryAccessoryCategoryFilter, MachineryAccessoryStatusWithSold, MachineryAccessoryTrailerTypes, MachineryDrives, MachineryGearOptions, MachineryStatusWithSold, ModelChangesType, OfferModelChange, OfferPositionPaymentStatus, OfferPositionTypes, OfferStatus, OfferType, OfferView, PositionTemplateType, PositionUnit } from '~/types'
import accessoryFilterConditions from '~/components/Shopping/accessoryFilterConditions'

export const machineryStatusToGerman: Record<MachineryStatusWithSold, string> = {
  creation: 'Registriert',
  delivery: 'Übergeben',
  reception: 'Aufgenommen',
  marking: 'Markiert',
  approval: 'Bestätigt',
  sold: 'Verkauft',
}

export const machineryAccessoryCreationStatusToGerman: Record<MachineryAccessoryStatusWithSold, string> = {
  creation: 'Registriert',
  marking: 'Markiert',
  approval: 'Bestätigt',
  sold: 'Verkauft',
}

export const machineryFieldsToCompareLabels: Record<z.infer<typeof machineryFieldsToCompare>, string> = {
  yearBuilt: 'Baujahr',
  liftingHeightInMillimeters: 'Hubhöhe',
  workHeightInMillimeters: 'Arbeitshöhe',
  serialNumber: 'Seriennummer',
}

export const machineryAccessoryCategoryToGerman: Record<MachineryAccessoryCategory, string> = {
  forkAdjuster: 'Zinkenverstellgerät',
  sideShifter: 'Seitenschieber',
  shovel: 'Schaufel',
  fork: 'Gabeln',
  hookShort: 'Haken kurz',
  hookLong: 'Haken lang',
  forkExtension: 'Gabelverlängerung',
  craneArm: 'Kranarm',
  forkCarriage: 'Gabelträger',
  clipOnHook: 'Aufsteckhaken',
  workBasket: 'Arbeitskorb',
  winch: 'Seilwinde',
  miscellaneous: 'Sonstiges',
  latticeMastWinch: 'Gittermast Seilwinde',
  wheels: 'Reifen',
  clipOnBasket: 'Aufsteckkorb',
  charger: 'Ladegerät',
  weights: 'Prüfgewicht',
  rollers: 'Rollen',
  stands: 'Böcke',
  ramps: 'Rampen',
  jib: 'Jib',
  heavyCasters: 'Schwerlastrollen',
  loadDistributionPlates: 'Lastverteilplatten',
  ballast: 'Ballast',
  rotaryDevice: 'Drehgerät',
  coilDorn: 'Coil Dorn',
  abusBogie: 'ABUS Drehgestell',
  heavyDutyLifter: 'Schwerlastheber',
  basePlate: 'Baseplate',
  trailer: 'Trailer',
  storageSpace: 'Lagerfläche',
  internalOperatingEquipment: 'Internes Betriebsgerät',
}
export const machineryAccessoryCategorySelectOptions = machineryAccessoryCategories.map(category => ({ label: machineryAccessoryCategoryToGerman[category], value: category }))

export const customerStatusToGerman: Record<CustomerStatus, string> = {
  potential: 'Interessent',
  current: 'Kunde',
}

export const customerTypesToGerman: Record<CustomerTypes, string> = {
  partner: 'Partnerlift',
  dealer: 'Händler',
  endCustomer: 'Endkunde',
}

export const offerStatusToGerman: Record<OfferStatus, string> = {
  offer: 'Angebot',
  order: 'Auftrag',
  invoiced: 'Abgerechnet',
  inquiry: 'Anfrage',
}

export const offerViewToGerman: Record<OfferView, string> = {
  'offer': 'Angebots-Übersicht',
  'order': 'Auftrags-Übersicht',
  'completed': 'Abgeschlosse Auftrags-Übersicht',
  'cancelled': 'Abgelehnte und stornierte Auftrags-Übersicht',
  'costs': 'Kosten-Übersicht',
  'all': 'Alle Angebote und Aufträge',
  'inquiry': 'Anfrage-Übersicht',
  'service-order': 'Auftrags-Übersicht',
  'offer-freelancer-sales': 'Angebots-Übersicht',
  'order-freelancer-sales': 'Auftrags-Übersicht',
}

export const mandatoryOfferFieldsToGerman: Record<string, string> = {
  contactPersonId: 'Besteller',
  customerId: 'Kunde',
  deliveryLocation: 'Lieferort / Einsatzort',

  description: 'Projekt Beschreibung',
  obligationStartsAt: 'Einsatzdatum',

  obligationEndsAt: 'Enddatum',
  rentalDays: 'Einsatzdauer',

  claimingPartnerName: 'Ansprechpartner Name',
  claimingPartnerEmail: 'Ansprechpartner Email',
  claimingPartnerTelephone: 'Ansprechpartner Telefon',
}

export const positionTypesToGerman: Record<PositionTemplateType, string> = {
  extraPosition: 'Extra Position',
  comment: 'Kommentar',
  logisticsTask: 'Lieferposition',
  insurance: 'Versicherung',
}

export const offerPositionTypesToGerman: Record<OfferPositionTypes, string> = {
  ...positionTypesToGerman,
  machinery: 'Gerät',
  machineryAccessory: 'Anbaugerät',
  machineryAccessoryCategory: 'Anbaugerät Kategorie',
  manualPosition: 'Manuell Position',
  creditNote: 'Rechnungskorrektur',
  generatedByAutomation: 'Generierte Position',
  invoice: 'Rechnung',
  itemSet: 'Set',
  itemSetAccessory: 'Set Anbaugerät',
  special: 'Sonderartikel',
  extraDays: 'Zusätzliche Miettage',
}

export const unitsToGerman: Record<PositionUnit, string> = {
  liter: 'Liter',
  day: 'Tage',
  flatRate: 'Pauschal',
  purchasePrice: 'Kaufpreis',
  operatingHours: 'Betriebsstunden',
  hours: 'Stunden',
  night: 'Nacht',
  pieces: 'Stück',
}

export const gearOptionsToGerman: Record<MachineryGearOptions, string> = {
  'Hydrostat': 'Hydrostat',
  'Converter': 'Wandler',
  'Keine Angabe': 'Keine Angabe',
}

export const logisticsFullStatusToGerman: Record<LogisticsRentalFullStatus, string> = {
  created: 'Erstellt',
  delivered: 'Übergeben',
  planned: 'Geplant',
  issued: 'Ausgegeben',
  loaded: 'Aufgeladen',
  terminated: 'Abgemeldet',
  collected: 'Abgeholt',
  returned: 'Abgeschlossen',
}

export const offerTypesToGerman: Record<OfferType, string> = {
  'rental': 'Vermietung',
  'sale': 'Verkauf',
  'special': 'Spezialauftrag',
  'service-project': 'Dienstleistung',
}

export const offerTypeCategorySelectOptions = Object.entries(offerTypesToGerman).map(category => ({ label: category[1], value: category[0] }))

export const customerFieldsToGerman: Record<keyof CustomerSchemaToCheckOrderAvailability, string> = {
  location: 'Straße und Ort',
  invoiceAddress: 'Abweichende Rechnungsadresse (Straße und Ort)',
  invoiceEmail: 'Rechnungs Email',
  email: 'E-Mail',
  telephone: 'Telefon',
  iban: 'IBAN',
  taxId: 'Steuernummer',
  vatID: 'Umsatzsteuer-ID',
}

export const logisticsVisualDetailsPollutionToGerman: Record<LogisticsVisualDetailsPollution, string> = {
  weak: 'Schwach',
  strong: 'Stark',
  none: 'Keine',
}

export const logisticsConditionRatingToGerman: Record<LogisticsConditionRating, string> = {
  new: 'neuwertig',
  good: 'gut',
  middle: 'mittel',
  damaged: 'beschädigt',
}

export const trpcCustomErrorMessagesToGerman = {
  DUPLICATE_SERIAL_NUMBER: 'Es gibt eine Maschine mit derselben Seriennummer.',
  MACHINERY_NOT_UNIQUE: 'Die Maschine mit dieser ID oder Seriennummer existiert bereits.',
  ASSIGN_MACHINE_TO_NON_EXISTING_VEHICLE: 'Das Fahrzeug existiert nicht.',
  MACHINERY_DOES_NOT_FIT_IN_VEHCILE: 'Die Maschine passt nicht in das Fahrzeug.',
  LOGISTICS_TASK_NOT_FOUND: 'Die Lieferposition wurde nicht gefunden.',
  LOGISTICS_TASK_NOT_BELONG_TO_SAME_OFFER: 'Die Lieferposition gehört nicht zu diesem Angebot.',
  LOGISTICS_TASK_HAS_DIFFERENT_TYPE: 'Die Lieferposition hat einen anderen Typ.',
  POSITION_NOT_FOUND: 'Die Position wurde nicht gefunden.',
  USED_BY_MORE_THAN_ONE_MACHINERY: 'Der Gerätetyp konnte nicht gelöscht werden, da ihm mindestens eine Maschine zugeordnet ist.',
  POSITIONS_TERMINATED_ON_DIFFERENT_DATE: 'Das Abmeldedaten die Positionen stimmen nicht überein.',

  // creating machineryAccessory offerPosition during Issuance
  POSITION_DOES_NOT_EXIST: 'Die Position existiert nicht.',
  MACHINERY_ACCESSORY_IS_PERMANENTLY_NOT_AVAILABLE: 'Das Lagertool-Gerät ist dauerhaft nicht verfügbar.',
  MACHINERY_ACCESSORY_IS_DEFECTIVE: 'Das Lagertool-Gerät ist defekt.',
  MACHINERY_ACCESSORY_IS_IN_ITEM_SET: 'Das Lagertool-Gerät ist in einem Set.',
  MACHINERY_ACCESSORY_IS_ATTACHED_TO_MACHINERY: 'Das Lagertool-Gerät ist an einer Maschine angebaut.',
  POSITION_IS_ALREADY_IN_THE_OFFER: 'Die Position ist bereits im Auftrag.',
  POSITION_IS_IN_OTHER_OFFER: 'Die Position ist in einem anderen Auftrag.',

  COMPANY_HAS_NO_CONTACT_PERSON: 'Die ausgewählte Firma hat keine Kontakt-Person',
  MACHINERY_HAS_NO_RECEPTION_MACHINERY: 'Der Maschine fehlen die notwendigen Aufnahme-Informationen',
  FREELANCER_CANNOT_ACCESS_OFFER: 'Das Angebot wurde nicht durch dich erstellt und kann deshalb auch nicht bearbeitet werden',

  STORAGE_PRODUCT_NOT_FOUND: 'Der Artikelstamm wurde nicht gefunden',
  STORAGE_PRODUCT_IS_DUPLICATED: 'Es gibt einen doppelten Artikelstamm',
  STORAGE_POSITION_AND_PRODUCT_PROJECT_MISMATCH: 'Lagerartikelstamm und Lagerposition stammen aus verschiedenen Projekten',
  STORAGE_POSITION_EDITING_DISABLED: 'Nach dem Statuswechsel nicht mehr bearbeitbar',
  STORAGE_POSITION_QUANTITY_EXCEEDED: 'Kann nicht mehr abrufen, als gespeichert ist',
  STORAGE_POSITION_LOAD_UNDO_DISABLED: 'Nur bereits aufgeladene Positionen können wieder entladen werden',
  STORAGE_POSITION_LOAD_DISABLED: 'Nur für die Auslagerung geplante Positionen können aufgeladen werden',

  SAME_EMAIL_ALREADY_EXISTS_FOR_CUSTOMER: 'Die E-Mail-Adresse existiert bereits für einen anderen Empfänger des aktuellen Kunden',

  SAME_EMAIL_USER_EXIST: 'Benutzer mit der gleichen E-Mail-Adresse existiert bereits, Bitte kontaktieren Sie Admin',
} as const

export type TrpcCustomErrorMessage = keyof typeof trpcCustomErrorMessagesToGerman

export const invoiceTypeToGerman: Record<InvoiceType, string> = {
  downpayment: 'Anzahlungsrechnung',
  partialpayment: 'Zwischenrechnung',
  fullpayment: 'Schlussrechnung',
  creditNote: 'Rechnungskorrektur',
  proformapayment: 'Proformarechnung',
}
export const invoiceTypeCategorySelectOptions = Object.entries(invoiceTypeToGerman).map(category => ({ label: category[1], value: category[0] }))

export const invoiceStatusToGerman: Record<InvoiceStatus, string> = {
  created: 'Erstellt',
  pending: 'Versendet',
  partiallyPaid: 'Teilweise bezahlt',
  paid: 'Bezahlt',
  cancelled: 'Storniert',
  notInvoiceable: 'Nicht abzurechnen',
}
export const invoiceStatusCategorySelectOptions = Object.entries(invoiceStatusToGerman).map(category => ({ label: category[1], value: category[0] }))

export const machineryDrivesToGerman: Record<MachineryDrives, string> = {
  electric: 'Elektro',
  diesel: 'Diesel',
  gas: 'Gas',
  noDrive: 'Kein Antrieb',
}

export const offerPositionPaymentStatusToGerman: Record<OfferPositionPaymentStatus, string> = {
  open: 'Offen',
  mixed: 'Gemischt',
  invoiced: 'Berechnet',
}

export const accessoryFilterConditionsFieldsToGerman: Record<keyof MachineryAccessoryCategoryFilter, string> = {
  producerCompanyName: 'Hersteller',
  typeName: 'Typ',
  storageLocation: 'Lagerplatz',
  drive: 'Antrieb',
  liftingWeight: 'Tragkraft (kg)',
  lengthInMillimeters: 'Länge (mm)',
  retractionLugsWidth: 'Einfahrlaschen-Breite (mm)',
  retractionLugsHeight: 'Einfahrlaschen-Höhe (mm)',
  retractionLugsDistanceInnerEdgeToInnerEdge: 'Einfahrlaschen-IK-IK (mm)',
  retractionLugsDistanceOuterEdgeToOuterEdge: 'Einfahrlaschen-AK-AK (mm)',
  weightInKilograms: 'Gewicht (kg)',
  fem: 'FEM',
  distanceInnerEdgeToInnerEdge: 'IK-IK (mm)',
  distanceOuterEdgeToOuterEdge: 'AK-AK (mm)',
  widthInMillimeters: 'Breite (mm)',
  heightInMillimeters: 'Höhe (mm)',
  cubicMeters: 'Cbm',
  wheelSize: 'Größe',
  comment: 'Kommentar',
  description: 'Beschreibung',
  productCode: 'Artikelnummer',
  volt: 'Volt',
}

export function accessoryFilterConditionsFieldsToGermanWithPrefix(category: MachineryAccessoryCategory, field: keyof MachineryAccessoryCategoryFilter): string {
  const prefix = accessoryFilterConditions[category]?.[field]?.compare === 'min' ? 'min.' : ''
  return `${prefix} ${accessoryFilterConditionsFieldsToGerman[field]}`
}

export const machineryAccessoryTrailerTypesToGerman: Record<MachineryAccessoryTrailerTypes, string> = {
  mega: 'Mega',
  standard: 'Standard',
  meusburger: 'Meusburger',
  lowloader: 'Tieflader',
  flatbed: 'Offen',
  box: 'Koffer',
}
export const machineryAccessoryTrailerTypeSelectOptions = machineryAccessoryTrailerTypes.options.map(trailerType => ({ label: machineryAccessoryTrailerTypesToGerman[trailerType], value: trailerType }))

export const taskActionsToGerman: Record<string, string> = {
  matchDeliveryDate: 'Achtung, das Anlieferdatum wurde im Auftrag angepasst. Bitte entsprechend umplanen.',
}

export const modelChangesFieldsToGerman: Record<ModelChangesType, (type?: string) => Record<string, string>> = {
  offer: (type?: string): Record<keyof OfferModelChange, string> => ({
    id: 'ID',
    type: 'Typ',
    isFavorite: 'Favorit',
    status: 'Status',
    obligationStartsAt: type === 'sale' ? 'Verkaufsdatum' : 'Startdatum',

    deliveryAt: 'Anlieferdatum und Zeit',

    otherInvoiceRecipientId: 'Abw. Rechnungsempfänger',

    paymentCondition: 'Zahlungskonditionen',

    customerId: 'Kunde',
    contactPersonId: 'Besteller',
    deliveryLocation: 'Lieferort',
    doesCustomerDoTask: 'Der Kunde holt die Ware selbst ab',
    customerOrderNumber: 'Kundenbestellnummer',

    claimingPartnerName: 'Ansprechpartner Name',
    claimingPartnerEmail: 'Ansprechpartner Telefon',
    claimingPartnerTelephone: 'Ansprechpartner E-mail',

    secondClaimingPartnerName: 'Ansprechpartner Name',
    secondClaimingPartnerEmail: 'Ansprechpartner Telefon',
    secondClaimingPartnerTelephone: 'Ansprechpartner E-mail',

    thirdClaimingPartnerName: 'Ansprechpartner Name',
    thirdClaimingPartnerEmail: 'Ansprechpartner Telefon',
    thirdClaimingPartnerTelephone: 'Ansprechpartner E-mail',

    isAccessoryOnlyOffer: `Nur Lagertool Geräte ${type === 'sale' ? 'verkaufen' : 'vermieten'}`,
    isConfirmed: 'Bestätigen',
    isCompleted: 'Abschließen',

    positions: 'Positionen',
    documents: 'Dokumente',

    rentalDays: 'Tage-Rabatt',
    breakDays: 'Tage-Pause',
    obligationEndsAt: 'Enddatum',
    obligationActuallyEndedAt: 'Datum der Abmeldung angeben',

    contactForCustomerEmail: 'Ansprechpartner für den Kunden',
    requestedAt: 'Anfragedatum',

    title: 'Titel',
    description: 'Projektbeschreibung',
    requiresDailyReports: 'Benötigt tägliche Berichte',

    wasFullyCompleted: 'Ist das Projekt vollständig abgeschlossen?',
    wasFullyCompletedComment: '',
    hadExtraExpenses: 'Sind ungeplante Aufwendungen entstanden?',
    hadExtraExpensesComment: '',
    hadCustomerDelays: 'Gab es zu dem Projekt kundenseitige Verzögerungen?',
    hadCustomerDelaysComment: '',
    hadExternalDelays: 'Gab es zu dem Projekt externe Verzögerungen?',
    hadExternalDelaysComment: '',
    hadExtraMaterials: 'Wurde zusätzliches Material / Maschinen benötigt?',
    hadExtraMaterialsComment: '',
    hadExtraWorkDone: 'Wurde zusätzliche Arbeit durchgeführt?',
    hadExtraWorkDoneComment: '',
    hadDefects: 'Gab es Reklamationen / Beschädigungen?',
    hadDefectsComment: '',
    completionComment: 'Sonstiger Kommentar',
    projectLeadName: '',
    projectLeadSignature: '',
    customerName: '',
    customerSignature: '',
    completionPhotos: '',
    temporaryCommentIdToConvert: '',
    isStorageSpaceOffer: 'ist Lagerfläche-Angebot',
    projectCode: 'Projektbezeichnung',

    signedAb: 'AB unterschrieben',
    saleProtocolPdf: 'Verkaufsprotokoll hinterlegt',
    machineryConvertedAt: 'Maschine umgebaut am',
    paidAmountWithoutInvoice: 'Bezahlung ohne Rechnungsbezug',

    isInternalProject: 'Internes Projekt',
    allowRentalPauseCreation: 'Mietpausen sind erlaubt',
  }),
}

export const invoicePrepareOfferPresetsToGerman: Record<InvoicableOffersWherePreset, string> = {
  'offer-completed': 'Auftrag abgeschlossen',
  'rentals-fully-terminated-but-not-returned': 'Auftrag vollständig abgemeldet (nicht abgeschlossen)',
  'not-invoiced-in-period': 'Auftrag ohne Rechnung in Periode',
  'not-partially-invoiced-in-period': 'Auftrag ohne Zwischenrechnung in Periode',
  'offer-cancelled': 'Auftrag storniert',
  'all': 'Auftrag offen',
}

export const logisticsTaskTypeToGerman: Record<LogisticsTaskType, string> = {
  'outbound': 'Anlieferung',
  'inbound': 'Abholung',
  'a-to-b': 'Spezialfahrt',
}

export const offerTypeToTitleSuffix: Record<OfferType, string> = {
  'rental': ' Vermietung',
  'sale': ' Verkauf',
  'service-project': '',
  'special': '',
}
