<script setup lang="ts">
import type { ApiMachineryGetById } from '~/types'
import { machineryFieldsToCompare } from '~/server/schemas'
import { machineryFieldsToCompareLabels } from '~/translations'

const props = withDefaults(defineProps<{ payload: { machineryId: string }, isDetailsPage?: boolean }>(), { isDetailsPage: false })
const emit = defineEmits<{ (e: 'save', payload: ApiMachineryGetById): void }>()

const { useremail } = useAuthorization()

const { machinery: queryMachinery, receptionMachinery: queryReceptionMachinery } = useQuery()

// Load and prepare machinery to confirm data
const { data: machinery } = queryMachinery.byId(computed(() => props.payload.machineryId))
const machineryToConfirm = ref<null | ApiMachineryGetById>(null)
watch(machinery, (machineryValue) => {
  if (machineryToConfirm.value || !machineryValue) {
    return
  }

  machineryToConfirm.value = {
    ...machineryValue,
    status: 'approval',
    releasedStateUpdatedAt: new Date(),
    releasedStateUpdatedByEmail: useremail.value,
  }
}, { immediate: true })

// Load and prepare receptionMachinery to compare data
const { data: receptionMachinery, isLoading: isLoadingReceptionMachinery } = queryReceptionMachinery.byMachineryId(computed(() => props.payload.machineryId))

const submitLabel = 'Speichern'.concat(props.isDetailsPage ? '' : ' und als "Bestätigt" markieren')

const moveToDetailsPage = () => navigateTo(`/machinery/${props.payload.machineryId}`)

const differentFields = computed(() => {
  const machineryToConfirmValue = machineryToConfirm.value
  if (!machineryToConfirmValue) {
    return []
  }

  const receptionMachineryValue = receptionMachinery.value
  if (!receptionMachineryValue) {
    return []
  }

  return machineryFieldsToCompare.options.filter((field) => {
    if (field === 'serialNumber') {
      return machineryToConfirmValue.serialNumber !== receptionMachineryValue.chassisNumber
    }

    return machineryToConfirmValue[field] !== receptionMachineryValue[field]
  })
})

const { openReleaseMachineryForFreelancerSalesPopup } = useGlobalOpeners()
</script>

<template>
  <FormKit
    v-if="machineryToConfirm && receptionMachinery"
    v-slot="{ value: formValue }"
    :value="machineryToConfirm"
    type="form"
    :submit-label="submitLabel"
    :disabled="machineryToConfirm.isDefective"
    @submit="(value: ApiMachineryGetById) => emit('save', value)"
  >
    <TheDevOnlyNiceJson :machinery-to-confirm :reception-machinery :form-value />
    <n-alert v-if="machineryToConfirm.isDefective" title="Das Geräte ist zurzeit Defekt." type="error" class="mb-3">
      Bitte reparieren sie den Defekt oder schieben ihn auf.
    </n-alert>
    <div v-if="!isDetailsPage" class="flex justify-between mb-1">
      <div>
        Alle erhobenen Parameter
      </div>
      <n-button quaternary @click="moveToDetailsPage">
        <template #icon>
          <Icon name="material-symbols:expand-content-rounded" />
        </template>
      </n-button>
    </div>
    <div v-if="!isDetailsPage" class="mb-4">
      <div v-if="receptionMachinery">
        <span v-if="differentFields.length === 0" class="text-blue-500">Geräteinformationen von Anlage und Aufnahme stimmen überein</span>
        <ul v-else class="px-3 list-disc text-red-500">
          <li v-for="field in differentFields" :key="field">
            <span v-if="field === 'serialNumber'">
              Serien Nr. (Anlage): {{ machineryToConfirm[field] ?? 'N/A' }} - Fahrgestell Nr. (Aufnahme): {{ receptionMachinery.chassisNumber ?? 'N/A' }}
            </span>
            <span v-else>
              {{ machineryFieldsToCompareLabels[field] }} (Anlage): {{ machineryToConfirm[field] ?? 'N/A' }} - {{ machineryFieldsToCompareLabels[field] }} (Aufnahme): {{ receptionMachinery[field] ?? 'N/A' }}
            </span>
          </li>
        </ul>
      </div>
      <span v-else-if="isLoadingReceptionMachinery">Lade Abgleich von Anlage und Aufnahme Geräteinformationen...</span>
      <span v-else class="text-red-500">Keine Geräteinformationen von der Aufnahme gefunden</span>
    </div>
    <div class="flex flex-col gap-2 mb-2">
      <div class="flex flex-row">
        <FormKit
          name="isReleasedForRent"
          type="checkbox"
          :label="$t('machinery.isReleasedForRentCheckbox.label')"
        />
        <FormKit
          name="isReleasedForSale"
          type="checkbox"
          :label="$t('machinery.isReleasedForSaleCheckbox.label')"
        />
        <FormKit
          name="isReleasedForOnlineSale"
          type="checkbox"
          :label="$t('machinery.isReleasedForOnlineSaleCheckbox.label')"
        />
      </div>
      <n-button
        @click="openReleaseMachineryForFreelancerSalesPopup.open({ mode: 'create', machineryIds: [machineryToConfirm.id] })"
      >
        Freelancer Freigabe verwalten
      </n-button>
    </div>
  </FormKit>
</template>
