<script setup lang="ts">
import type { ApiLogisticsTaskGetById, ApiLogisticsTaskGetByIdPositionsToShip, ApiOfferPositionGetAll, MachineryAccessoryCategory, MachineryCategories, OfferStatus } from '~/types'
import { accessoryFilterConditionsFieldsToGermanWithPrefix, machineryAccessoryCategoryToGerman, offerStatusToGerman } from '~/translations'
import { offerPositionsToShipSchema } from '~/server/schemas'
import { NuxtLink } from '#components'
import { groupByKey } from '~/components/Offer/CreateOrEdit/offerPositionHelpers'

const { openLogisticsIssuanceMachineryList: { data: popupData, close: closePopup }, openPositionsDetailsPopup } = useGlobalOpeners()

const isOutbound = computed(() => popupData.value?.taskType === 'outbound')

const { logisticsTask: logisticsTaskQuery, offerPosition: offerPositionQuery } = useQuery()
const { data: logisticsTask, error: errorLogisticsTask } = logisticsTaskQuery.byIdOrThrow(computed(() => popupData.value?.id))

const isServiceProject = computed(() => logisticsTask.value?.offer?.type === 'service-project')

const allIssuedOrAllReturned = computed(() => {
  const fieldToCheck = isOutbound.value ? 'isIssued' : 'isReturned'
  return logisticsTask.value?.positionsToShip.every(pos => pos[fieldToCheck]) ?? false
})

function updateLogisticsTaskAndClosePopup(isIssuancePaused: boolean) {
  if (logisticsTask.value?.doAllowPositionAdditionDuringIssuance) {
    updateIssuancePasueStatus.mutate({ id: logisticsTask.value.id, isIssuancePaused })
  }

  closePopup()
}

const offerPositionWhere = computed(() => {
  if (!logisticsTask.value) {
    return { offerId: 'not-existant' }
  }

  return {
    offerId: logisticsTask.value.offerId ?? undefined,
    type: { in: offerPositionsToShipSchema.options },
  }
})

const { data: offerPositions } = offerPositionQuery.all(offerPositionWhere)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const update = useMutation({
  mutationFn: $trpc.offerPosition.updateStatus.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Ausgebe-Status konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])
    notification.success({ title: 'Der Ausgebe-Status wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const connectMachineryAccessoryToMachineryAndUpdateOfferPosition = useMutation({
  mutationFn: async (data: { machineryId: string, offerPositionId: string, machineryAccessoryIds: string[] }) => {
    await $trpc.offerPosition.updateMachineryAccessoryId.mutate({ id: data.offerPositionId, machineryAccessoryId: data.machineryAccessoryIds[0] })
    return $trpc.machinery.attachMachineryAccessories.mutate({ id: data.machineryId, machineryAccessoryIds: data.machineryAccessoryIds })
  },
  onError: makeTrpcErrorToast(notification, { description: 'Das Anbaugerät konnte nicht verbunden werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['accessories'] }),
    ])
    closeConnectpopup()
    notification.success({ title: 'Das Anbaugerät wurde erfolgreich verbunden', duration: 4500 })
  },
})

const deleteOfferPositionCreatedFromIssuance = useMutation({
  mutationFn: $trpc.offerPosition.logisticsTour.issuance.deleteOfferPositionCreatedFromIssuance.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Position konnte nicht gelöscht werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['accessories'] }),
    ])

    popupConfirmSetAndOrPositionDelete.value = null
    notification.success({ title: 'Die Position wurde gelöscht', duration: 4500 })
  },
})

const updateIssuancePasueStatus = useMutation({
  mutationFn: $trpc.logisticsTask.updatePauseStatus.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Lieferung konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])
    notification.success({ title: 'Die Lieferung wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const machineryPositionInOfferByGroup = computed(() => {
  if (!offerPositions.value) {
    return {}
  }

  const machineryPositions = offerPositions.value.filter(pos => pos.type === 'machinery')
  return groupByKey(machineryPositions, 'groupInOffer')
})

const accessoryPositionsInOfferByGroup = computed(() => {
  if (!offerPositions.value) {
    return {}
  }

  const accessoryPositions = offerPositions.value.filter(pos => ['machineryAccessory', 'machineryAccessoryCategory'].includes(pos.type))
  return groupByKey(accessoryPositions, 'groupInOffer')
})

const isForkReplacedByGroup = computed(() => {
  if (!machineryPositionInOfferByGroup.value || !isOutbound.value) {
    return {}
  }

  const machineryPositions = Object.keys(machineryPositionInOfferByGroup.value).map((key) => {
    let isForkReplaced = false
    const machineryPosition = machineryPositionInOfferByGroup.value[key]?.[0]
    if (!machineryPosition?.machinery) {
      return [key, isForkReplaced]
    }

    if (machineryPosition.machinery.category !== 'forklift') {
      return [key, isForkReplaced]
    }

    const forkPosition = accessoryPositionsInOfferByGroup.value[key]?.find(acc => acc.machineryAccessoryCategory === 'fork' || acc.machineryAccessory?.category === 'fork')
    if (!forkPosition) {
      return [key, isForkReplaced]
    }

    const attachedFork = machineryPosition.machinery.attachedMachineryAccessories?.find(acc => acc.category === 'fork')
    if (!attachedFork) {
      return [key, isForkReplaced]
    }

    isForkReplaced = Boolean(forkPosition.isForkReplaceRequired && attachedFork.id === forkPosition.machineryAccessoryId)
    return [key, isForkReplaced]
  })

  return Object.fromEntries(machineryPositions)
})

/** Positions */
const positionsToShowOnTop = computed(() => {
  if (!logisticsTask.value) {
    return []
  }
  if (logisticsTask.value.type === 'inbound') {
    return logisticsTask.value.positionsToShip
  }

  return logisticsTask.value.positionsToShip.filter(pos => !pos.isCreatedFromIssuance)
})

const machineryPositions = computed(() => positionsToShowOnTop.value.filter(p => p.type === 'machinery' && p.machineryId))
const accessoryPositions = computed(() => positionsToShowOnTop.value.filter(p => ['machineryAccessory', 'machineryAccessoryCategory'].includes(p.type)) ?? [])
const itemSetPositions = computed(() => positionsToShowOnTop.value.filter(p => p.type === 'itemSet') ?? [])

const positionsCreatedFromIssuance = computed(() => {
  if (!logisticsTask.value || logisticsTask.value.type === 'inbound') {
    return []
  }

  return logisticsTask.value.positionsToShip.filter(pos => pos.isCreatedFromIssuance)
})

const accessoryPositionsCreatedFromIssuance = computed(() => positionsCreatedFromIssuance.value.filter(p => ['machineryAccessory', 'machineryAccessoryCategory'].includes(p.type)) ?? [])
const itemSetPositionsCreatedFromIssuance = computed(() => positionsCreatedFromIssuance.value.filter(p => p.type === 'itemSet') ?? [])

const specialPositions = computed(() => logisticsTask.value?.positionsToShip.filter(p => p.type === 'special'))

/** Table */
const logisticsCustomerOverview: OneDataColumn<Exclude<ApiLogisticsTaskGetById['offer'], null>['customer']>[] = [
  {
    title: 'Kunde',
    key: 'name',
  },
]

const logisticsMachineryOverview: OneDataColumn<Exclude<ApiLogisticsTaskGetByIdPositionsToShip['machinery'], null>>[] = [
  {
    title: 'Geräte Nr.',
    key: 'id',
  },
  {
    title: 'Geräte Typ',
    key: 'type',
    render: ({ type }) => type.name,
  },
  {
    title: 'Hersteller',
    key: 'producerCompanyName',
  },
]

const logisticsItemSetOverview: OneDataColumn<ApiLogisticsTaskGetByIdPositionsToShip>[] = [
  {
    title: 'Geräte Nr.',
    key: 'itemSetId',
  },
]

const logisticsItemSetMemberOverview: OneDataColumn< ApiLogisticsTaskGetByIdPositionsToShip['itemSetMember'][number]>[] = [
  {
    title: 'Geräte Nr.',
    key: 'machineryAccessoryId',
    render: p => p.machineryAccessory?.id ?? 'offen',
  },
  {
    title: 'Geräte-Kategorie',
    key: 'machineryAccessoryCategory',
    render: (p) => {
      const machineryAccessoryCategory = p.machineryAccessoryCategory ?? p.machineryAccessory?.category
      if (machineryAccessoryCategory === 'miscellaneous' && p.machineryAccessory?.description) {
        return p.machineryAccessory.description
      }
      return machineryAccessoryCategoryToGerman[machineryAccessoryCategory as MachineryAccessoryCategory]
    },
  },
]

function getAccessoryIdLink(id: string) {
  return h(NuxtLink, {
    onClick: () => openPositionsDetailsPopup.open({ type: 'machineryAccessory', id }),
    class: 'underline text-blue-500 hover:cursor-pointer',
  }, id)
}

function logisticsAccessoryOverview(category: MachineryAccessoryCategory): OneDataColumn<ApiLogisticsTaskGetByIdPositionsToShip>[] {
  return [
    {
      title: 'Geräte Nr.',
      key: 'machineryAccessoryId',
      renderComponent: (p) => {
        const isNotCategory = !!p.machineryAccessory
        const hasCompatibleAccessory = !!p.compatibleMachineryAccessoryId
        const accessoryId = p.machineryAccessory?.id ?? ''
        const compatibleAccessoryId = p.compatibleMachineryAccessoryId ?? ''

        const compatibleAccessoryLink = hasCompatibleAccessory
          ? [
              ' (muss kompatibel sein mit ',
              getAccessoryIdLink(compatibleAccessoryId),
              ')',
            ]
          : []

        return h('p', [
          (isNotCategory ? getAccessoryIdLink(accessoryId) : 'offen'),
        ].concat(compatibleAccessoryLink))
      },
    },
    {
      title: 'Geräte-Kategorie',
      key: 'machineryAccessoryCategory',
      render: (p) => {
        const machineryAccessoryCategory = p.machineryAccessoryCategory ?? p.machineryAccessory?.category
        if (machineryAccessoryCategory === 'miscellaneous' && p.machineryAccessory?.description) {
          return p.machineryAccessory.description
        }
        return machineryAccessoryCategoryToGerman[machineryAccessoryCategory as MachineryAccessoryCategory]
      },
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'producerCompanyName'),
      key: 'producerCompanyName',
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'liftingWeight'),
      key: 'liftingWeight',
      render: ({ liftingWeight }) => liftingWeight?.toLocaleString('de-DE'),
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'lengthInMillimeters'),
      key: 'lengthInMillimeters',
      render: ({ lengthInMillimeters }) => lengthInMillimeters?.toLocaleString('de-DE'),
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'widthInMillimeters'),
      key: 'widthInMillimeters',
      render: ({ widthInMillimeters }) => widthInMillimeters?.toLocaleString('de-DE'),
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'heightInMillimeters'),
      key: 'heightInMillimeters',
      render: ({ heightInMillimeters }) => heightInMillimeters?.toLocaleString('de-DE'),
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'retractionLugsWidth'),
      key: 'retractionLugsWidth',
      render: ({ retractionLugsWidth }) => retractionLugsWidth?.toLocaleString('de-DE'),
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'retractionLugsHeight'),
      key: 'retractionLugsHeight',
      render: ({ retractionLugsHeight }) => retractionLugsHeight?.toLocaleString('de-DE'),
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'retractionLugsDistanceInnerEdgeToInnerEdge'),
      key: 'retractionLugsDistanceInnerEdgeToInnerEdge',
      render: ({ retractionLugsDistanceInnerEdgeToInnerEdge }) => retractionLugsDistanceInnerEdgeToInnerEdge?.toLocaleString('de-DE'),
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'retractionLugsDistanceOuterEdgeToOuterEdge'),
      key: 'retractionLugsDistanceOuterEdgeToOuterEdge',
      render: ({ retractionLugsDistanceOuterEdgeToOuterEdge }) => retractionLugsDistanceOuterEdgeToOuterEdge?.toLocaleString('de-DE'),
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'wheelSize'),
      key: 'wheelSize',
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'fem'),
      key: 'fem',
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'typeName'),
      key: 'typeName',
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'cubicMeters'),
      key: 'cubicMeters',
      render: ({ cubicMeters }) => cubicMeters?.toLocaleString('de-DE'),
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'distanceInnerEdgeToInnerEdge'),
      key: 'distanceInnerEdgeToInnerEdge',
      render: ({ distanceInnerEdgeToInnerEdge }) => distanceInnerEdgeToInnerEdge?.toLocaleString('de-DE'),
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'distanceOuterEdgeToOuterEdge'),
      key: 'distanceOuterEdgeToOuterEdge',
      render: ({ distanceOuterEdgeToOuterEdge }) => distanceOuterEdgeToOuterEdge?.toLocaleString('de-DE'),
      hideIfNullish: true,
    },

    // category Fork specific
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'widthInMillimeters'),
      key: 'widthInMillimeters',
      render: ({ machineryAccessory }) => category === 'fork' ? machineryAccessory?.widthInMillimeters?.toLocaleString('de-DE') : null,
      isVisible: ({ machineryAccessory }) => Boolean(category === 'fork' && machineryAccessory?.widthInMillimeters),
    },

    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'comment'),
      key: 'comment',
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'description'),
      key: 'description',
      hideIfNullish: true,
    },
    {
      title: accessoryFilterConditionsFieldsToGermanWithPrefix(category, 'productCode'),
      key: 'productCode',
      hideIfNullish: true,
    },
  ]
}

const logisticsSpecialPositionOverview: OneDataColumn<{ title: string }>[] = [
  {
    title: 'Titel',
    key: 'title',
  },
]

const { openLogisticsIssuanceEnterMachineryId, openSetCreateOrUpdateFromIssuancePopup, openSpecialPositionsCreateOrEditPopup } = useGlobalOpeners()

const positionsToUpdate = computed(() => logisticsTask.value?.positionsToShip)

function scanMachineryAccessoryQrCode(accessoryPosition: ApiLogisticsTaskGetByIdPositionsToShip | ApiLogisticsTaskGetByIdPositionsToShip['itemSetMember'][number], type: 'scanQR' | 'typeId') {
  if (!positionsToUpdate.value || !popupData.value) {
    return
  }

  if (accessoryPosition.machineryAccessory) {
    openLogisticsIssuanceEnterMachineryId.open({ mode: 'id', machineryId: accessoryPosition.machineryAccessory.id, offerPositionId: accessoryPosition.id, taskType: popupData.value.taskType, type })
  } else if (accessoryPosition.machineryAccessoryCategory) {
    openLogisticsIssuanceEnterMachineryId.open({ mode: 'category', machineryAccessoryCategory: accessoryPosition.machineryAccessoryCategory, offerPositionId: accessoryPosition.id, taskType: popupData.value.taskType, type })
  }
}

function updateIsIssued(positionId: string | undefined, newIsIssued: boolean) {
  if (!positionId) {
    return
  }

  return update.mutate([{ id: positionId, isIssued: newIsIssued }])
}

function updateIsReturned(positionId: string | undefined, newIsReturned: boolean) {
  if (!positionId) {
    return
  }

  return update.mutate([{ id: positionId, isReturned: newIsReturned }])
}

const { payload: connectPayload, open: openConnectPopup, close: closeConnectpopup } = usePopup<{ machinery: Exclude<ApiOfferPositionGetAll['machinery'], null>, forkPosition: ApiLogisticsTaskGetById['positionsToShip'][number] }>()
const { payload: postionCreatePayload, open: openPostionCreatePopup, close: closePostionCreatePopup } = usePopup<{ type: 'scanQR' | 'typeId' }>()
const popupConfirmSetAndOrPositionDelete = ref<null | ApiLogisticsTaskGetByIdPositionsToShip>(null)

function connect(machineryId?: string, offerPositionId?: string, machineryAccessoryIds: string[] = []) {
  if (!machineryId || !offerPositionId) {
    return
  }

  connectMachineryAccessoryToMachineryAndUpdateOfferPosition.mutate({ machineryId, offerPositionId, machineryAccessoryIds })
}

const machineryAccessoryWhere = computed(() => {
  if (!connectPayload.value) {
    return {}
  } else if (connectPayload.value.forkPosition.type === 'machineryAccessory' && connectPayload.value.forkPosition.machineryAccessory) {
    const { widthInMillimeters } = connectPayload.value.forkPosition.machineryAccessory
    return {
      widthInMillimeters: widthInMillimeters ?? undefined,
    }
  }

  return {
    widthInMillimeters: connectPayload.value.forkPosition.widthInMillimeters ?? undefined,
  }
})

const buttonCaption = computed(() => {
  let buttonCaption = ''

  if (isOutbound.value) {
    buttonCaption += 'Ausgabe '
  } else {
    buttonCaption += 'Rücknahme '
  }

  if (allIssuedOrAllReturned.value) {
    buttonCaption += 'abschließen'
  } else {
    buttonCaption += 'unterbrechen'
  }

  return buttonCaption
})

const hiddenAttachedAccessories = computed(() => accessoryPositions.value.filter(a => a.isAttachedAccessoryHidden))

const machineryDefectCount = computed(() => machineryPositions.value?.filter(position => position.machinery?.defects.length)?.map(position => [position.machinery?.id, position.machinery?.defects.length]) ?? [])
</script>

<template>
  <div>
    <ThePopup v-if="connectPayload" :show="Boolean(connectPayload)" width="90%" @close="closeConnectpopup">
      <ServiceMachineryAccessoryConnectionsOfMachinery
        v-if="connectPayload"
        :machinery-id="connectPayload.machinery.id"
        :machinery-category="connectPayload.machinery.category as MachineryCategories"
        :already-checked-row-keys="connectPayload.machinery.attachedMachineryAccessories.map(accessory => accessory.id)"
        :compatible-machinery-type-id="connectPayload.machinery.typeId"
        :accessory-categories-to-show="['fork']"
        :machinery-accessory-where="machineryAccessoryWhere"
        select-type="single"
        @connect="machineryAccessoryIds => connect(connectPayload?.machinery.id, connectPayload?.forkPosition.id, machineryAccessoryIds)"
      />
    </ThePopup>
    <TheConfirmPopup
      v-if="popupConfirmSetAndOrPositionDelete"
      @confirm="deleteOfferPositionCreatedFromIssuance.mutate(popupConfirmSetAndOrPositionDelete)"
      @close="popupConfirmSetAndOrPositionDelete = null"
    >
      {{ popupConfirmSetAndOrPositionDelete.type === 'itemSet' ? 'Das Set und die Position werden gelöscht.' : 'Die Position wird gelöscht.' }}
    </TheConfirmPopup>

    <LogisticsIssuanceAddPositionPopup
      v-if="postionCreatePayload && logisticsTask?.offerId"
      :show="Boolean(postionCreatePayload)"
      :type="postionCreatePayload.type"
      :offer-id="logisticsTask.offerId"
      :logistics-task-id="logisticsTask.id"
      @close="closePostionCreatePopup"
    />

    <ThePopup
      v-if="popupData && logisticsTask && logisticsTask.offer"
      :show="Boolean(logisticsTask)"
      :title="`${isOutbound ? 'Ausgabe' : 'Rücknahme'} ${logisticsTask ? `${offerStatusToGerman[logisticsTask.offer.status as OfferStatus]} ${logisticsTask.offer.id} (${logisticsTask.id})` : 'N/A'}`"
      @close="closePopup"
    >
      <div v-if="errorLogisticsTask">
        Unerwarteter Fehler beim Laden der Logistik
      </div>
      <div v-if="logisticsTask" class="my-2 flex flex-col gap-7 mb-6">
        <n-alert v-if="hiddenAttachedAccessories.length" type="warning">
          Bitte stellen Sie sicher, dass {{ hiddenAttachedAccessories.length === 1 ? 'das Anbaugerät' : 'die Anbaugeräte' }}
          {{ hiddenAttachedAccessories.map(a => a.id).join(', ') }} nicht mit ausgegeben wird.
        </n-alert>
        <n-collapse>
          <n-collapse-item :title="`Kommentare (${logisticsTask.offer._count.comments})`">
            <CommentList :id="logisticsTask.offer.id" type="offer" :refresh-queries="['logisticsTask']" hide-list-title />
          </n-collapse-item>
        </n-collapse>
        <n-alert
          v-for="machinery of machineryDefectCount"
          :key="machinery[0]"
          type="info"
        >
          Bitte beachten Sie, dass die Maschine ({{ machinery[0] }}) {{ machinery[1] }} unaufschiebbaren Defekt(e) hat.
        </n-alert>
        <hr class="py-0">
        <TableOneDataColumn class="mb-2" :config="{ columns: logisticsCustomerOverview, data: logisticsTask.offer.customer }" />
        <div
          v-for="machineryPosition of machineryPositions"
          :key="machineryPosition.id"
          class="Container gap-y-4"
        >
          <TableOneDataColumn :config="{ columns: logisticsMachineryOverview, data: machineryPosition.machinery }" />
          <LogisticsIssuanceMachineryListActions
            :position="machineryPosition"
            :is-outbound="isOutbound"
            :is-position-collected="machineryPosition.isCollected"
            @open-issuance-popup="(type: 'scanQR' | 'typeId') => machineryPosition?.machinery && popupData && openLogisticsIssuanceEnterMachineryId.open({ mode: 'id', machineryId: machineryPosition.machinery.id, offerPositionId: machineryPosition.id, taskType: popupData.taskType, type })"
            @update-is-issued="updateIsIssued(machineryPosition.id, !machineryPosition.isIssued)"
            @update-is-returned="updateIsReturned(machineryPosition.id, !machineryPosition.isReturned)"
          />
        </div>

        <div v-if="itemSetPositions?.length" class="space-y-8">
          <h2 class="font-bold text-lg">
            Zusätzliche Geräte
          </h2>
          <div v-for="itemSetPosition in itemSetPositions" :key="itemSetPosition.id" class="flex flex-col gap-y-4">
            <TableOneDataColumn
              :config="{ columns: logisticsItemSetOverview, data: itemSetPosition }"
            />
            <LogisticsIssuanceMachineryListActions
              v-if="itemSetPosition.itemSetId"
              :position="itemSetPosition"
              :item-set-id="itemSetPosition.itemSetId"
              :is-issuance-disabled="!itemSetPosition.itemSetMember.length"
              :is-outbound="isOutbound"
              :is-position-collected="itemSetPosition.isCollected"
              @open-issuance-popup="(type: 'scanQR' | 'typeId') => itemSetPosition.itemSetId && popupData && openLogisticsIssuanceEnterMachineryId.open({ mode: 'id', machineryId: itemSetPosition.itemSetId, offerPositionId: itemSetPosition.id, taskType: popupData.taskType, type })"
              @update-is-issued="updateIsIssued(itemSetPosition.id, !itemSetPosition.isIssued)"
              @update-is-returned="updateIsReturned(itemSetPosition.id, !itemSetPosition.isReturned)"
            />

            <n-collapse>
              <n-collapse-item :title="`Lagertool-Geräte in Set ${itemSetPosition.itemSetId}`">
                <div class="w-full flex flex-col gap-y-2">
                  <div v-for="accessory in itemSetPosition.itemSetMember" :key="accessory.id" class="flex flex-col gap-y-4">
                    <TableOneDataColumn
                      :config="{ columns: logisticsItemSetMemberOverview, data: accessory }"
                    />
                    <LogisticsIssuanceMachineryListActions
                      :position="accessory"
                      :is-outbound="isOutbound"
                      :is-position-collected="itemSetPosition.isCollected"
                      @open-issuance-popup="(type: 'scanQR' | 'typeId') => scanMachineryAccessoryQrCode(accessory, type)"
                      @update-is-issued="updateIsIssued(accessory.id, !accessory.isIssued)"
                      @update-is-returned="updateIsReturned(accessory.id, !accessory.isReturned)"
                    />
                  </div>
                </div>
              </n-collapse-item>
            </n-collapse>
          </div>
        </div>

        <div v-if="accessoryPositions?.length" class="space-y-8">
          <h2 class="font-bold text-lg">
            Zusätzliche Geräte
          </h2>
          <div v-for="accessory in accessoryPositions" :key="accessory.id" class="Container gap-y-4">
            <TableOneDataColumn
              :config="{ columns: logisticsAccessoryOverview((accessory.machineryAccessoryCategory ?? accessory.machineryAccessory?.category) as MachineryAccessoryCategory), data: accessory }"
            />
            <div v-if="accessory.isForkReplaceRequired && machineryPositionInOfferByGroup[accessory.groupInOffer]?.[0]?.machinery && isOutbound">
              <p className="text-gray-600 text-sm mb-1">
                Vor der Ausgabe müssen die Gabeln gewechselt werden. Bitte beachten Sie, dass die neuen Gabeln den Kriterien oben entsprechen<br>
                Bitte wählen Sie zunächst die neu angebrachten Gabeln hier aus und starten danach die Ausgabe.
              </p>
              <n-button
                class="w-60"
                @click="openConnectPopup({ machinery: machineryPositionInOfferByGroup[accessory.groupInOffer][0].machinery!, forkPosition: accessory })"
              >
                Verbundene Gabeln verwalten
              </n-button>
            </div>
            <LogisticsIssuanceMachineryListActions
              :position="accessory"
              :is-outbound="isOutbound"
              :is-position-collected="accessory.isCollected"
              :is-issuance-disabled="Boolean(!isForkReplacedByGroup[accessory.groupInOffer] && accessory.isForkReplaceRequired && isOutbound)"
              @open-issuance-popup="(type: 'scanQR' | 'typeId') => scanMachineryAccessoryQrCode(accessory, type)"
              @update-is-issued="updateIsIssued(accessory.id, !accessory.isIssued)"
              @update-is-returned="updateIsReturned(accessory.id, !accessory.isReturned)"
            />
          </div>
        </div>

        <div v-if="logisticsTask.doAllowPositionAdditionDuringIssuance" class="flex flex-col gap-4">
          <div>
            <p>Hinzufügen weitere Lagertool Geräte wurde angefordert</p>
            <div><span class="font-semibold mr-1">Beschreibung:</span> {{ logisticsTask.positionAdditionDuringIssuanceComment }}</div>
          </div>

          <div class="flex flex-col gap-4 w-full">
            <div class="flex flex-col gap-2">
              <p class="font-semibold">
                Set erstellen
              </p>
              <div>
                <n-button
                  @click="openSetCreateOrUpdateFromIssuancePopup.open({ mode: 'create', offerId: logisticsTask.offer.id, logisticsTaskId: logisticsTask.id, setIndex: itemSetPositionsCreatedFromIssuance.length })"
                >
                  Erstellen
                </n-button>
              </div>
            </div>

            <div class="flex flex-col gap-2">
              <p class="font-semibold">
                Lagertool-Gerät oder Set hinzufügen
              </p>
              <div class="flex gap-2">
                <n-button @click="openPostionCreatePopup({ type: 'scanQR' })">
                  Gerät scannen
                </n-button>
                <n-button @click="openPostionCreatePopup({ type: 'typeId' })">
                  Manuell Id eingeben
                </n-button>
              </div>
            </div>

            <div v-if="isServiceProject" class="flex flex-col gap-4">
              <p class="font-semibold">
                Sonderartikel {{ specialPositions?.length ? 'bearbeiten' : 'erstellen' }}
              </p>

              <div>
                <n-button
                  v-if="specialPositions?.length"
                  @click="openSpecialPositionsCreateOrEditPopup.open({ mode: 'update', offerId: logisticsTask.offer.id, logisticsTaskId: logisticsTask.id })"
                >
                  Anzeigen/Bearbeiten
                </n-button>

                <n-button
                  v-else
                  @click="openSpecialPositionsCreateOrEditPopup.open({ mode: 'create', offerId: logisticsTask.offer.id, logisticsTaskId: logisticsTask.id })"
                >
                  Erstellen
                </n-button>
              </div>
            </div>
          </div>

          <div v-for="(itemSetPosition, idx) in itemSetPositionsCreatedFromIssuance" :key="itemSetPosition.id" class="flex flex-col gap-y-4">
            <div>
              <h2 class="font-bold">
                Set {{ idx + 1 }}
              </h2>
              <TableOneDataColumn
                :config="{ columns: logisticsItemSetOverview, data: itemSetPosition }"
              />
            </div>
            <LogisticsIssuanceMachineryListActions
              v-if="itemSetPosition.itemSetId"
              :position="itemSetPosition"
              :item-set-id="itemSetPosition.itemSetId"
              :is-issuance-disabled="!itemSetPosition.itemSetMember.length"
              :is-outbound="isOutbound"
              :is-position-collected="itemSetPosition.isCollected"
              :show-delete-button="true"
              @open-issuance-popup="(type: 'scanQR' | 'typeId') => itemSetPosition.itemSetId && popupData && openLogisticsIssuanceEnterMachineryId.open({ mode: 'id', machineryId: itemSetPosition.itemSetId, offerPositionId: itemSetPosition.id, taskType: popupData.taskType, type })"
              @update-is-issued="updateIsIssued(itemSetPosition.id, !itemSetPosition.isIssued)"
              @update-is-returned="updateIsReturned(itemSetPosition.id, !itemSetPosition.isReturned)"
              @delete-position="popupConfirmSetAndOrPositionDelete = itemSetPosition"
            />

            <n-collapse>
              <n-collapse-item :title="`Lagertool-Geräte in Set ${itemSetPosition.itemSetId}`">
                <div class="w-full flex flex-col gap-y-2">
                  <div v-for="accessory in itemSetPosition.itemSetMember" :key="accessory.id" class="flex flex-col gap-y-4">
                    <TableOneDataColumn
                      :config="{ columns: logisticsItemSetMemberOverview, data: accessory }"
                    />
                    <LogisticsIssuanceMachineryListActions
                      :position="accessory"
                      :is-outbound="isOutbound"
                      :is-position-collected="itemSetPosition.isCollected"
                      @open-issuance-popup="(type: 'scanQR' | 'typeId') => scanMachineryAccessoryQrCode(accessory, type)"
                      @update-is-issued="updateIsIssued(accessory.id, !accessory.isIssued)"
                      @update-is-returned="updateIsReturned(accessory.id, !accessory.isReturned)"
                    />
                  </div>
                </div>
              </n-collapse-item>
            </n-collapse>
          </div>

          <div v-for="(accessory, idx) in accessoryPositionsCreatedFromIssuance" :key="accessory.id" class="Container gap-y-4">
            <div>
              <h2 class="font-bold">
                Position {{ idx + 1 }}
              </h2>
              <TableOneDataColumn
                :config="{ columns: logisticsAccessoryOverview((accessory.machineryAccessoryCategory ?? accessory.machineryAccessory?.category) as MachineryAccessoryCategory), data: accessory }"
              />
            </div>
            <LogisticsIssuanceMachineryListActions
              :position="accessory"
              :is-outbound="isOutbound"
              :is-position-collected="accessory.isCollected"
              :show-delete-button="true"
              @open-issuance-popup="(type: 'scanQR' | 'typeId') => scanMachineryAccessoryQrCode(accessory, type)"
              @update-is-issued="updateIsIssued(accessory.id, !accessory.isIssued)"
              @update-is-returned="updateIsReturned(accessory.id, !accessory.isReturned)"
              @delete-position="popupConfirmSetAndOrPositionDelete = accessory"
            />
          </div>
        </div>

        <div v-if="specialPositions?.length">
          <div v-for="(position, idx) in specialPositions" :key="idx" class="flex flex-col gap-y-4">
            <h2 class="font-bold">
              Sonderartikel {{ idx + 1 }}
            </h2>

            <TableOneDataColumn :config="{ columns: logisticsSpecialPositionOverview, data: position }" />

            <LogisticsIssuanceMachineryListActions
              :position="position"
              :is-outbound="isOutbound"
              :is-position-collected="position.isCollected"
              :show-delete-button="true"
              @update-is-issued="updateIsIssued(position.id, !position.isIssued)"
              @update-is-returned="updateIsReturned(position.id, !position.isReturned)"
              @delete-position="popupConfirmSetAndOrPositionDelete = position"
            />
          </div>
        </div>

        <div class="flex gap-2 w-full">
          <n-button
            v-if="logisticsTask.doAllowPositionAdditionDuringIssuance && allIssuedOrAllReturned"
            class="flex-grow"
            type="default"
            @click="() => updateLogisticsTaskAndClosePopup(true)"
          >
            Ausgabe unterbrechen
          </n-button>

          <n-button
            class="flex-grow"
            type="primary"
            @click="() => updateLogisticsTaskAndClosePopup(false)"
          >
            {{ buttonCaption }}
          </n-button>
        </div>
      </div>
    </ThePopup>
  </div>
</template>

<style scoped>
  .Container {
    @apply flex flex-col
  }
</style>
