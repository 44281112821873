<script setup lang="ts">
const { t: $t } = useI18n()

import type { ApiPublicMachineryGetById, ApiReceptionMachineryGetById } from '~/types'

const props = defineProps<{ machineryId: string }>()

const { machinery: queryMachinery } = useQuery()
const { data: machinery, isLoading: isLoadingMachinery, error: errorMachinery } = queryMachinery.public.byId(ref(props.machineryId))

const machineryType = computed(() => machinery.value?.type ?? null)
const isTractor = computed(() => machinery.value?.category === 'tractor')

const machineryPublicType = computed((): OneDataColumn<{ name: string }>[] => [
  {
    title: $t('publicDetailsPage.machinery.type'),
    key: 'name',
  },
])

const machineryPublicVehicleData = computed((): OneDataColumn<ApiPublicMachineryGetById>[] => [
  {
    title: $t('publicDetailsPage.machinery.vehicleDataColumn.producer'),
    key: 'producerCompanyName',
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.vehicleDataColumn.rubric'),
    key: 'machineryRubric',
    render: ({ machineryRubric }) => $t(`machinery.rubric.${machineryRubric}`),
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.vehicleDataColumn.liftingWeight'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.liftingWeight,
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.vehicleDataColumn.type'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.type?.name,
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.vehicleDataColumn.yearBuilt'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.yearBuilt,
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.vehicleDataColumn.product'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.product,
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.vehicleDataColumn.drive'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.drive ? $t(`machinery.drive.${receptionMachinery.drive.name}`) : undefined,
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.vehicleDataColumn.weight'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.weight,
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.vehicleDataColumn.operatingHours'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.operatingHours ? (receptionMachinery.operatingHours + 50).toLocaleString('de-DE') : undefined,
    hideIfNullish: true,
  },
])

const machineryPublicDimensions = computed((): OneDataColumn<ApiPublicMachineryGetById>[] => [
  {
    title: $t('publicDetailsPage.machinery.dimensionsColumn.mast'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => $t(`machinery.mast.${receptionMachinery?.mast}`),
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.dimensionsColumn.liftingHeight'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.liftingHeightInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.dimensionsColumn.overallHeight'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => {
      const heightInMillimeters = receptionMachinery?.mastOverallHeightInMillimeters ?? receptionMachinery?.overallHeightInMillimeters
      return heightInMillimeters?.toLocaleString('de-DE')
    },
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.dimensionsColumn.forkCarriageWidth'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.forkCarriageWidthInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.dimensionsColumn.fem'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.fem,
    hideIfNullish: true,
  },
])

const machineryPublicMeasurements = computed((): OneDataColumn<ApiPublicMachineryGetById>[] => [
  {
    title: $t('publicDetailsPage.machinery.measurementsColumn.lengthToForkface'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.lengthToForkfaceInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.measurementsColumn.totalLength'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.totalLengthInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.measurementsColumn.wheelbase'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.wheelbaseInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.measurementsColumn.overallWidth'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.overallWidthInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.measurementsColumn.groundClearanceCenter'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.groundClearanceCenter,
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.measurementsColumn.cabinHeight'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.cabinHeightInMillimeters?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
])

const machineryPublicBatteryData = computed((): OneDataColumn<ApiPublicMachineryGetById>[] => [
  {
    title: $t('publicDetailsPage.machinery.batteryDataColumn.producer'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.battery?.producerCompanyName,
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.batteryDataColumn.type'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.battery?.typeName,
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.batteryDataColumn.weight'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.battery?.weight?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.batteryDataColumn.volt'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.battery?.volt,
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.batteryDataColumn.yearBuilt'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.battery?.yearBuilt,
    hideIfNullish: true,
  },
])

const machineryPublicMotorData = computed((): OneDataColumn<ApiPublicMachineryGetById>[] => [
  {
    title: $t('publicDetailsPage.machinery.driveColumn.motorProducer'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.motor?.producerCompanyName,
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.driveColumn.power'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.motor?.power?.toLocaleString('de-DE'),
    hideIfNullish: true,
  },
  {
    title: $t('publicDetailsPage.machinery.driveColumn.type'),
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.motor?.typeName,
    hideIfNullish: true,
  },
])

const receptionMachinerySpecialEquipment = computed((): OneDataColumn<ApiReceptionMachineryGetById | Exclude<ApiPublicMachineryGetById['receptionMachinery'], null>>[] => [
  {
    title: $t('publicDetailsPage.machinery.specialEquipment'),
    key: 'receptionMachinerySpecialEquipmentRelation',
    render: ({ receptionMachinerySpecialEquipmentRelation, customSpecialEquipment }) => {
      const specialEquipments = receptionMachinerySpecialEquipmentRelation?.receptionMachinerySpecialEquipments?.map(type => type.typeName)
      if (!specialEquipments) {
        return customSpecialEquipment
      }

      if (customSpecialEquipment) {
        specialEquipments.push(customSpecialEquipment)
      }

      return specialEquipments.join(', ')
    },
    hideIfNullish: true,
  },
])
</script>

<template>
  <CookieConsent locale="de" />
  <div>
    <div v-if="!machinery && !isLoadingMachinery">
      <TheDataCard>
        {{ $t('publicDetailsPage.machinery.notFound') }}
      </TheDataCard>
    </div>
    <div v-else class="flex flex-col gap-2 lg:flex-row">
      <div class="flex flex-col w-full gap-2 lg:w-1/2">
        <TheDataCard :title="$t('publicDetailsPage.machinery.vehicleData')" :is-loading="isLoadingMachinery">
          <TableOneDataColumn :config="{ data: machinery, columns: machineryPublicVehicleData }" />
        </TheDataCard>
        <TheDataCard v-if="!isTractor" :title="$t('publicDetailsPage.machinery.dimensions')" :is-loading="isLoadingMachinery">
          <TableOneDataColumn :config="{ data: machinery, columns: machineryPublicDimensions }" />
        </TheDataCard>
        <TheDataCard v-if="!isTractor" :title="$t('publicDetailsPage.machinery.specialEquipment')" :is-loading="isLoadingMachinery" :error="errorMachinery">
          <TableOneDataColumn :config="{ data: machinery?.receptionMachinery, columns: receptionMachinerySpecialEquipment }" />
        </TheDataCard>
        <TheDataCard v-if="!isTractor" :title="$t('publicDetailsPage.machinery.measurements')" :is-loading="isLoadingMachinery">
          <TableOneDataColumn :config="{ data: machinery, columns: machineryPublicMeasurements }" />
        </TheDataCard>
        <TheDataCard :title="$t('publicDetailsPage.machinery.batteryData')" :is-loading="isLoadingMachinery">
          <TableOneDataColumn :config="{ data: machinery, columns: machineryPublicBatteryData }" />
        </TheDataCard>
        <TheDataCard :title="$t('publicDetailsPage.machinery.drive')" :is-loading="isLoadingMachinery">
          <TableOneDataColumn :config="{ data: machinery, columns: machineryPublicMotorData }" />
        </TheDataCard>
        <TheDataCard :title="$t('publicDetailsPage.machinery.machineryEquipment')" :is-loading="isLoadingMachinery">
          <div v-if="machinery" class="flex flex-col gap-2">
            <p class="font-semibold">
              {{ $t('publicDetailsPage.machinery.machineryEquipmentColumn.accessories') }}
            </p>
            <div class="whitespace-pre">
              {{ !machinery.marketingMachineryAccessoryDescription ? 'N/A' : machinery.marketingMachineryAccessoryDescription }}
            </div>

            <p class="font-semibold">
              {{ $t('publicDetailsPage.machinery.machineryEquipmentColumn.special') }}
            </p>
            <div class="whitespace-pre">
              {{ !machinery.marketingSpecialEquipmentDescription ? 'N/A' : machinery.marketingSpecialEquipmentDescription }}
            </div>
          </div>
        </TheDataCard>
        <MachineryCompatibleAccessoriesAndConnectCard
          v-if="machinery && machinery?.category !== 'forklift' && !isTractor"
          :is-loading="isLoadingMachinery"
          :error="errorMachinery"
          :machinery="machinery"
          :do-load-compatible-accessories="true"
          :is-update-disabled="true"
          :is-in-public-page="true"
        />
      </div>
      <div class="flex flex-col w-full gap-2 lg:w-1/2">
        <TheDataCard :title="$t('publicDetailsPage.machinery.machineryType')" :is-loading="isLoadingMachinery">
          <TableOneDataColumn :config="{ data: machineryType, columns: machineryPublicType }" />
          <FileView :label="$t('publicDetailsPage.machinery.dataSheet')" :files="machinery?.type.documentDataSheetFiles ?? []" collapsed-by-default="if-empty" />
          <FileView :label="$t('publicDetailsPage.machinery.manual')" :files="machinery?.type.documentManualFiles ?? []" collapsed-by-default="if-empty" />
          <FileView :label="$t('publicDetailsPage.machinery.videos')" :files="machinery?.type.documentVideoFiles ?? []" collapsed-by-default="if-empty" />
          <FileView :label="$t('publicDetailsPage.machinery.videos')" :files="machinery?.type.documentExternalVideoFiles ?? []" collapsed-by-default="if-empty" />
        </TheDataCard>
        <TheDataCard :title="$t('publicDetailsPage.machinery.photos')" :is-loading="isLoadingMachinery">
          <FileView :label="$t('publicDetailsPage.machinery.machineryPhotos')" :files="machinery?.marketingPhotos ?? []" collapsed-by-default="if-empty" />
        </TheDataCard>
        <TheDataCard :title="$t('publicDetailsPage.machinery.videos')" :is-loading="isLoadingMachinery">
          <FileView :label="$t('publicDetailsPage.machinery.machineryVideos')" :files="machinery?.marketingVideos ?? []" collapsed-by-default="if-empty" />
          <FileView :label="$t('publicDetailsPage.machinery.externalMachineryVideos')" :files="machinery?.externalMarketingVideos ?? []" collapsed-by-default="if-empty" />
        </TheDataCard>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Hide cards that are empty */
::v-deep(.n-card):has(table:only-child > tbody:empty) {
  display: none;
}
</style>
