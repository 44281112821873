import { z } from 'zod'
import { machineryFemsWithFiveAndNone, machineryTireColors, specialPermissionsSchema } from './schemas/machineryAndMachineryAccessoryCommon'
import { emailSchema, filesFieldSchemaNullish, idObjectSchema, idSchema, pathInObjectFilesSchema } from './schemas/common'
import { zDiscriminatedUnion, zObject } from './schemas/zObject'
import { roles as userRoleSchema } from '~/authorization'
import { StandardWorkingDays, StoragePositionOffloadedWith, StoragePositionStatus } from '~/types'

/* Common re-exports */
export {
  idSchema,
  idObjectSchema,
  idObjectDuplicateSchema,
  emailSchema,
  emailObjectSchema,
  filesFieldSchemaNullish,
  zodArrayAsString,
} from './schemas/common'

export const datesForOverlapCheck = z.object({ start: z.coerce.date(), end: z.coerce.date().nullable() })

/* Machinery re-exports */
export { machineryFems, machineryTireColors, machineryColors } from './schemas/machineryAndMachineryAccessoryCommon'

export const machineryCategories = z.enum(['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'trailer', 'tractor'])
// machineryStatusWithSold is status that are used for search & show in frontend
export const machineryStatusWithSold = z.enum(['creation', 'delivery', 'reception', 'marking', 'approval', 'sold'])
// machineryStatusSchema is status that are saved in database
export const machineryStatusSchema = z.enum(['creation', 'delivery', 'reception', 'marking', 'approval'])
export const machineryMasts = z.enum(['standard', 'duplex', 'triplex', 'quattro', 'telescopic', 'articulated'])
export const machineryMastsForBuehnen = z.enum(['telescopic', 'articulated', 'scissor', 'verticalMastPlatform'])
export const machineryHals = z.enum(['A', 'B', 'Keine Angabe'])
export const machinerySpecialEquipments = z.enum(['STVO', 'Arbeitsscheinwerfer', 'Bluespot', 'Rundumleuchte', 'Radio', '3. Ventil', '4. Ventil', 'Kabine', 'Halbkabine', 'Heizung', 'Klima', 'Waage', 'Anhängerkupplung', 'Spiegel', 'Verbindungskabel'])
export const machineryInsuranceDeductible = z.enum(['2000', '4000', '5000'])

const machinerySpecialEquipmentsToCategoriesSchema = z.record(machinerySpecialEquipments, z.array(machineryCategories))

export const machinerySpecialEquipmentsToCategories: z.infer<typeof machinerySpecialEquipmentsToCategoriesSchema> = {
  'STVO': ['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'tractor', 'trailer'],
  'Arbeitsscheinwerfer': ['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'tractor', 'trailer'],
  'Bluespot': ['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'tractor', 'trailer'],
  'Rundumleuchte': ['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'tractor', 'trailer'],
  'Spiegel': ['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'tractor', 'trailer'],
  'Radio': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  '3. Ventil': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  '4. Ventil': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Kabine': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Halbkabine': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Heizung': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Klima': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Waage': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Anhängerkupplung': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Verbindungskabel': ['trailer'],
}

export const equipmentType = z.enum(['Kabine', 'Beleuchtung', 'Zusatzhydraulik', 'Sonstiges'])
const machinerySpecialEquipmentsToEquimentTypeSchema = z.record(machinerySpecialEquipments, equipmentType)

export const machinerySpecialEquipmentsToEquimentType: z.infer<typeof machinerySpecialEquipmentsToEquimentTypeSchema> = {
  'STVO': 'Beleuchtung',
  'Arbeitsscheinwerfer': 'Beleuchtung',
  'Bluespot': 'Beleuchtung',
  'Rundumleuchte': 'Beleuchtung',
  'Spiegel': 'Sonstiges',
  'Radio': 'Sonstiges',
  '3. Ventil': 'Zusatzhydraulik',
  '4. Ventil': 'Zusatzhydraulik',
  'Kabine': 'Kabine',
  'Halbkabine': 'Kabine',
  'Heizung': 'Kabine',
  'Klima': 'Kabine',
  'Waage': 'Sonstiges',
  'Anhängerkupplung': 'Sonstiges',
  'Verbindungskabel': 'Sonstiges',
}

export const machineryTireTypes = z.enum(['Vollgummi', 'Luft', 'Waben', 'SE'])
export const machineryDrives = z.enum(['electric', 'diesel', 'gas', 'noDrive'])

export const machineryRubrics = z.enum([
  'electricForklift',
  'dieselForklift',
  'gasForklift',
  'allTerrainForklift',
  'telescopicForklift',
  'rotatingForklift',
  'sideLoader',
  'fourWayForklift',
  'scissorWorkPlatform',
  'articulatedTelescopicWorkPlatform',
  'telescopicWorkPlatform',
  'verticalMastPlatform',
  'reachTruck',
  'constructionMachinery',
  'crane',
  'tugger',
  'warehouseTechnology',
  'trailer',
  'tractor',
  'selfPropelledVerticalWorkPlatform',
  'truckWorkPlatform',
])
export const machineryPurchasing = z.enum(['Lieferung', 'Abholung'])

export const machinerySteeringType = z.enum(['1-Achsenlenkung', '2-Achsenlenkung'])
export const machineryFieldsToCompare = z.enum(['yearBuilt', 'liftingHeightInMillimeters', 'workHeightInMillimeters', 'serialNumber'])

export const machineryPaymentConditions = z.enum([
  'Zahlung bei Abholung wenn Fahrer vor Ort',
  'Zahlung vor Abholung',
  'Zahlung vor Lieferung',
  'Zahlung wenn Gerät bei uns',
  'Sonstiges',
])

export const machineryByIdIncludeSchema = zObject({
  include: zObject({
    receptionMachinery: z.boolean().optional(),
    defects: zObject({
      where: z.any(),
    }).optional(),
    freelancerConditionsForFreelancersThatCanSellMe: z.boolean().optional(),
    partnerConditionsForPartnersThatCanSellMe: z.boolean().optional(),
    storageLocationUpdatedBy: z.boolean().optional(),
    storageLocationCreatedBy: z.boolean().optional(),
    releasedStateUpdatedBy: z.boolean().optional(),
    lastUVVDateUpdatedBy: z.boolean().optional(),
    inspectionDateUpdatedBy: z.boolean().optional(),
  }).optional(),
})

export const createMachinery = zObject({
  id: z.number().min(1).max(5999).optional().nullable()
    .or(z.string().optional())
    .transform(val => val ? val.toString() : undefined),
  category: machineryCategories,
  deliveryCompanyName: z.string().min(1),
  producerCompanyName: z.string().min(1),
  machineryRubric: machineryRubrics,
  driveId: idSchema,
  yearBuilt: z.number().int().min(1900).max(2030),
  pricePurchaseEuros: z.number().min(0),
  priceRecommendedToSellFor: z.number().min(0).nullish(),
  priceHanselmannPubliclySellsFor: z.number().min(0).nullish(),
  paymentCondition: z.string().min(1),
  liftingHeightInMillimeters: z.number().min(0).nullable(),
  liftingWeight: z.number().min(0),
  forkLengthInMillimeters: z.number().min(0).nullable(),
  workHeightInMillimeters: z.number().min(0).nullable(),
  storageLocation: z.string().min(1),
  typeId: z.string().min(1),
  typeAddition: z.string().nullish(),
  comment: z.string().nullish(),
  isReleasedForRent: z.boolean().default(false),
  isReleasedForSale: z.boolean().default(false),
  isReleasedForOnlineSale: z.boolean().default(false),
  isForeignMachinery: z.boolean().default(false),
  purchasing: machineryPurchasing.nullish(),
  additionalDeliveryCost: z.number().min(0).nullable(),
  serialNumber: z.string().min(1).nullish(),
  deliveryDate: z.coerce.date().nullish(),
  lastUVVDate: z.coerce.date().nullish(),
  technicalInspectionDate: z.coerce.date().nullish(),
  securityInspectionDate: z.coerce.date().nullish(),
  speedometerInspectionDate: z.coerce.date().nullish(),
  specialPermissions: specialPermissionsSchema,
  documentFiles: filesFieldSchemaNullish,
  documentUVVFiles: pathInObjectFilesSchema,
  documentCEFiles: pathInObjectFilesSchema,
  documentCircuitDiagramsFiles: pathInObjectFilesSchema,
  documentSparePartsCatalogFiles: pathInObjectFilesSchema,
  documentWeightTest: pathInObjectFilesSchema,
  transportFiles: pathInObjectFilesSchema,
  marketingPhotos: pathInObjectFilesSchema,
  marketingVideos: pathInObjectFilesSchema,
  externalMarketingVideos: filesFieldSchemaNullish,
  previousStatus: machineryStatusSchema.nullish(),
  insuranceDeductible: machineryInsuranceDeductible.nullish(),
  foreignMachineryCustomerId: idSchema.nullish(),
  licensePlateNumber: z.string().min(1).nullish(),
})

export const updateMachinery = createMachinery.merge(zObject({
  id: idSchema,
  status: machineryStatusSchema,
  releasedStateUpdatedAt: z.coerce.date().nullish(),
  releasedStateUpdatedByEmail: z.string().nullish(),
  lastUVVDateUpdatedAt: z.coerce.date().nullish(),
  lastUVVDateUpdatedByEmail: z.string().nullish(),
  inspectionDateUpdatedAt: z.coerce.date().nullish(),
  inspectionDateUpdatedByEmail: z.string().nullish(),
  marketingMachineryAccessoryDescription: z.string().nullish(),
  marketingSpecialEquipmentDescription: z.string().nullish(),
}))

export const receptionMachineryTireCountSchema = z.enum(['3-Rad', '4-Rad', 'Keine Auswahl'])

export const createReceptionMachineryTire = zObject({
  producerCompanyName: z.string().nullish().optional(),
  typeName: z.string().nullish().optional(),
  color: machineryTireColors.nullish().optional(),
  tireCount: z.string().nullish().optional(),
  rearCondition: z.string().nullish().optional(),
  frontSize: z.string().nullish().optional(),
  frontCondition: z.string().nullish().optional(),
  rearSize: z.string().nullish().optional(),
  rearCount: z.number().min(0).nullish().optional(),
  frontCount: z.number().min(0).nullish().optional(),
  photos: filesFieldSchemaNullish.optional(),
})

export const createReceptionMachineryBattery = zObject({
  producerCompanyName: z.string().nullish().optional(),
  typeName: z.string().nullish().optional(),
  weight: z.number().min(0).nullish().optional(),
  volt: z.string().nullish().optional(),
  yearBuilt: z.number().int().min(1900).max(2030).nullish().optional(),
  serialNumber: z.string().nullish().optional(),
  ampere: z.number().nullish(),
  photos: filesFieldSchemaNullish.optional(),
})

export const machineryGears = z.enum(['Hydrostat', 'Converter', 'Keine Angabe'])

export const createReceptionMachineryMotor = zObject({
  producerCompanyName: z.string().nullish().optional(),
  typeName: z.string().nullish().optional(),
  power: z.number().min(0).nullish().optional(),
  gear: z.string().nullish().optional(),
  gearProducer: z.string().nullish().optional(),
  serialNumber: z.string().nullish().optional(),
  hasAdBlue: z.boolean().nullish().optional(),
  photos: filesFieldSchemaNullish.optional(),
})

export const receptionMachinerySchema = zObject({
  machineryId: idSchema.nullish(),
  offerPositionId: idSchema.nullish(),
  yearBuilt: z.number().int().min(1900).max(2030).nullish().optional(),
  seats: z.number().int().min(0).nullish().optional(),
  serialNumber: z.string().nullish().optional(),
  typeId: z.string().nullish().optional(),
  typeAddition: z.string().nullish().optional(),
  driveId: idSchema.nullish().optional(),
  chassisNumber: z.string().nullish().optional(),
  product: z.string().nullish().optional(),
  key: z.string().nullish().optional(),
  initialOperatingHours: z.number().min(0).nullish().optional(),
  weight: z.number().min(0).nullish().optional(),
  liftingHeightInMillimeters: z.number().min(0).nullable().optional(),
  liftingWeight: z.number().min(0).nullable().optional(),
  freeLiftInMillimeters: z.number().min(0).nullish().optional(),
  mastOverallHeightInMillimeters: z.number().min(0).nullish().optional(),
  overallHeightInMillimeters: z.number().min(0).nullish().optional(),
  overallWidthInMillimeters: z.number().min(0).nullish().optional(),
  forkCarriageWidthInMillimeters: z.number().min(0).nullish().optional(),
  cabinHeightInMillimeters: z.number().min(0).nullish().optional(),
  lengthToForkfaceInMillimeters: z.number().min(0).nullish().optional(),
  loadCenter: z.string().nullish().optional(),
  wheelbaseInMillimeters: z.number().min(0).nullish().optional(),
  mast: z.string(machineryMasts || machineryMastsForBuehnen).nullish().optional(),
  workHeightInMillimeters: z.number().min(0).nullable().optional(),
  fem: z.string(machineryFemsWithFiveAndNone).nullish().optional(),
  hals: z.string(machineryHals).nullish().optional(),
  terminalWest: z.boolean().nullish().optional(),
  addOns: z.string().nullish().optional(),
  shaft: z.boolean().nullish().optional(),
  initialLift: z.boolean().nullish().optional(),
  groundClearanceCenter: z.string().nullish().optional(),
  opticalCondition: z.string().nullish().optional(),
  technicalCondition: z.string().nullish().optional(),
  steeringType: z.string().nullish().optional(),
  platformLengthInMillimeters: z.number().min(0).nullish().optional(),
  platformWidthInMillimeters: z.number().min(0).nullish().optional(),
  platformHeightInMillimeters: z.number().min(0).nullish().optional(),
  sideReachInMillimeters: z.number().min(0).nullish().optional(),
  totalLengthInMillimeters: z.number().min(0).nullish().optional(),
  dimensionHeightInMillimeters: z.number().min(0).nullish().optional(),
  generalPhotos: filesFieldSchemaNullish.optional(),
  cabinInsidePhotos: filesFieldSchemaNullish.optional(),
  cabinOutsidePhotos: filesFieldSchemaNullish.optional(),
  basicDataPhotos: filesFieldSchemaNullish.optional(),
  videos: filesFieldSchemaNullish.optional(),
  category: machineryCategories,
  extraAddOns: z.string().nullish().optional(),
  extraAccessories: z.string().nullish().optional(),
  customSpecialEquipment: z.string().nullish().optional(),
})

export const receptionMachineryHistorySchema = receptionMachinerySchema.omit({ offerPositionId: true }).extend({ receptionMachineryId: idSchema })

const receptionMachineryRelationsSchema = zObject({
  machinerySpecialEquipments: z.array(idSchema),
  machineryTire: createReceptionMachineryTire,
  machineryTireTypes: z.array(idSchema).nullish(),
  machineryBattery: createReceptionMachineryBattery,
  machineryStatus: machineryStatusSchema.nullish(),
  machineryMotor: createReceptionMachineryMotor,
})

export const innerReceptionMachinery = receptionMachinerySchema.omit({
  machineryId: true,
  generalPhotos: true,
  cabinInsidePhotos: true,
  cabinOutsidePhotos: true,
  videos: true,
  basicDataPhotos: true,
})

const receptionMachineryBattery = createReceptionMachineryBattery.omit({ photos: true }).nullish()
const receptionMachineryMotor = createReceptionMachineryMotor.omit({ photos: true }).nullish()

export const receptionMachineryWithoutImageAndMachineryId = zObject({
  receptionMachinery: innerReceptionMachinery,
  machinerySpecialEquipments: z.array(idSchema),
  machineryTire: createReceptionMachineryTire.omit({ photos: true }).nullish(),
  machineryTireTypes: z.array(idSchema).nullish(),
  machineryBattery: receptionMachineryBattery,
  machineryMotor: receptionMachineryMotor,
})

export const receptionMachineryCompareSchema = innerReceptionMachinery.extend({
  tire: createReceptionMachineryTire.omit({ photos: true }).extend({
    receptionMachineryTireTireTypeRelation: zObject({
      receptionMachineryTireTypes: z.array(idObjectSchema).nullish(),
    }),
  }).nullish(),
  battery: receptionMachineryBattery,
  motor: receptionMachineryMotor,
  operatingHours: z.number().min(0).nullish(),
})

export const receptionMachineryRelationsPrefilledData = receptionMachineryWithoutImageAndMachineryId.extend({
  receptionMachinery: innerReceptionMachinery.omit({
    typeId: true,
    category: true,
    serialNumber: true,
    chassisNumber: true,
  }),
})

export const createReceptionMachinery = zObject({ receptionMachinery: receptionMachinerySchema, accessoryId: idSchema.optional() }).merge(receptionMachineryRelationsSchema)

export const createReceptionMachineryWithProps = createReceptionMachinery.merge(zObject({
  drive: machineryDrives,
}))
export const updateMachineryOperationHours = zObject({ id: idSchema, operatingHours: z.number(), initialOperatingHours: z.number().min(0).nullish().optional() })
export const updateReceptionMachinery = zObject({ receptionMachinery: receptionMachinerySchema
  .merge(idObjectSchema) })
  .merge(receptionMachineryRelationsSchema)
  .extend({ isModification: z.boolean().optional() })
export const updateReceptionMachineryWithProps = updateReceptionMachinery.merge(zObject({
  drive: machineryDrives,
}))

/**
 * Machinery Type
 */
export const createMachineryTypeSchema = zObject({
  name: z.string().min(1),
  category: machineryCategories,
  documentDataSheetFiles: pathInObjectFilesSchema,
  documentManualFiles: pathInObjectFilesSchema,
  documentVideoFiles: pathInObjectFilesSchema,
  homepageImages: pathInObjectFilesSchema,
  documentExternalVideoFiles: filesFieldSchemaNullish,
})

export const updateMachineryTypeSchema = createMachineryTypeSchema.merge(idObjectSchema)

/*
 * Machinery Accessories re-exports
 */
export {
  machineryAccessoryTrailerTypes,
  machineryAccessoryConditions,
  machineryAccessoryCategorySchema,
  individualCreateInnerMachineAccessorySchemas,
  createInnerMachineryAccessory,
  machineryAccessoryStatusWithSold,
  updateOrCreateMachineryAccessorySchema,
} from './schemas/machineryAccessory'

export const machineryMenuSchema = z.enum(['all', 'price'])

export const customerStatusSchema = z.enum(['potential', 'current'])
export const customerTypes = z.enum(['partner', 'dealer', 'endCustomer'])
export const taxRates = z.enum(['0', '0.07', '0.19'])
export const paymentConditions = z.enum(['instantNet', 'instant', 'beforeShipment', 'beforeCollection', 'thirtyDaysNet', 'fourteenDaysNet', 'discount', 'twentyOneDaysDiscount', 'twentyOneDaysThreePercentDiscount'])
export const customerLanguages = z.enum(['DE', 'EN', 'PL', 'FR'])

export const contactPersonSchema = zObject({
  id: idSchema,
  name: z.string(),
  phoneNumberOffice: z.string().nullish(),
  phoneNumberRadio: z.string().nullish(),
  email: z.string().nullish(),
  function: z.string().nullish(),
  hasHOSAccess: z.boolean(),
}).refine((data) => {
  if (data.hasHOSAccess === true && data.email === null) {
    return false
  }
  return true
})

export const customerSchema = zObject({
  type: customerTypes,
  name: z.string(),
  nameTwo: z.string().nullish(),
  nameThree: z.string().nullish(),
  location: z.string().transform(val => val.trim() === '' ? null : val).nullish(),
  invoiceAddress: z.string().transform(val => val.trim() === '' ? null : val).nullish(),
  invoiceEmail: z.string().nullish(),
  email: z.string().nullish(),
  telephone: z.string().nullish(),
  telefax: z.string().nullish(),
  iban: z.string().nullish(),
  taxId: z.string().nullish(),
  vatID: z.string().nullish(),
  taxRate: taxRates.nullish(),
  paymentCondition: paymentConditions,
  language: customerLanguages,
  country: z.string(),
  isBlocked: z.boolean().optional(),
  isExternalStorageCustomer: z.boolean().optional(),
  isExternalStorageSupplier: z.boolean().optional(),
})

export const customerSchemaToCheckOrderAvailability = zObject({
  location: z.string().min(1),
  invoiceAddress: z.string().nullish(),
  invoiceEmail: z.string().nullish(),
  email: z.string().min(1),
  telephone: z.string().min(1),
  iban: z.string().min(1),
  taxId: z.string().min(1),
  vatID: z.string().min(1),
})

const customerRelationsSchema = zObject({
  contactPersons: z.array(contactPersonSchema).min(1, 'Mindestens ein Ansprechpartner ist erforderlich'),
})

export const createCustomer = zObject({ customer: customerSchema }).merge(customerRelationsSchema)
export const updateCustomer = zObject({ customer: customerSchema.merge(idObjectSchema).merge(zObject({ status: customerStatusSchema })) }).merge(customerRelationsSchema)

export const offerViewSchema = z.enum(['offer', 'order', 'completed', 'cancelled', 'all', 'costs', 'inquiry', 'service-order', 'offer-freelancer-sales', 'order-freelancer-sales'])
export const offerStatusSchema = z.enum(['inquiry', 'offer', 'order', 'invoiced'])
export const specialOfferViewSchema = z.enum(['all', 'cancelled', 'offer', 'order', 'completed'])

export const marketingViewSchema = z.enum(['preparation', 'edit'])

export const positionTemplateTypes = z.enum(['comment', 'extraPosition', 'logisticsTask', 'insurance'])
export const positionUnits = z.enum(['liter', 'day', 'flatRate', 'purchasePrice', 'operatingHours', 'hours', 'night', 'pieces'])
export const positionItemSet = z.enum(['description', 'producerCompanyName', 'typeName', 'liftingWeightInKilograms', 'weightInKilograms', 'transportLengthInMillimeters', 'transportWidthInKilograms', 'transportHeightInKilograms'])

const positionTemplateTitleSchema = zObject({
  id: idSchema.optional(),
  title: z.string().min(1),
  locale: supportedLocaleSchema,
})

export const positionTemplateSchema = zObject({
  type: positionTemplateTypes,
  titles: z.array(positionTemplateTitleSchema),
  pricePerUnit: z.number(),
  quantity: z.number().default(1),
  unit: positionUnits,
  canUseRental: z.boolean(),
  canUseSale: z.boolean(),
  canUseServiceProject: z.boolean(),
  isApplicableToAllOffers: z.boolean().default(false),
})

export const updateOfferIsCancelledSchema = idObjectSchema.extend({ isCancelled: z.boolean() })
export const updateOfferIsFavoriteSchema = idObjectSchema.extend({ isFavorite: z.boolean() })

const positionExtraIdsSchema = zObject({
  machineryTypeIds: z.array(idSchema),
  customerIds: z.array(idSchema),
  machineryDriveIds: z.array(idSchema),
})

export const createPositionTemplateSchema = zObject({ position: positionTemplateSchema }).merge(positionExtraIdsSchema)
export const updatePositionTemplateSchema = zObject({ position: positionTemplateSchema.merge(idObjectSchema) }).merge(positionExtraIdsSchema)

export const offerPositionGroupTypeSchema = z.enum(['machineryAndAccessories', 'globalTermsAndConditions', 'offerAndCustomerDefault', 'invoiceRelated', 'specialPositions'])
export const offerPositionTypeSchema = z.enum([
  'machinery',
  'machineryAccessory',
  'machineryAccessoryCategory',
  'insurance',
  'extraPosition',
  'manualPosition',
  'logisticsTask',
  'comment',
  'itemSet',
  'itemSetAccessory',
  'special',
  'creditNote',
  'generatedByAutomation',
  'invoice',
  'extraDays',
])
export const offerPositionsToShipSchema = z.enum(['machinery', 'machineryAccessory', 'machineryAccessoryCategory', 'itemSet', 'special'])
export const offerPositionsToShipInvoiceSchema = z.enum(['machinery', 'machineryAccessory', 'machineryAccessoryCategory', 'itemSet', 'comment'])

export const generatedByAutomationSchema = z.enum(['tankFilling', 'adblueFilling', 'emptyGasTanks', 'gasTanks', 'pollution'])

export const logisticsTaskTypeSchema = z.enum(['outbound', 'inbound', 'a-to-b'])
export const logisticsTourDirectionsSchema = z.enum(['outbound', 'inbound'])
const createBaseOfferPositionSchema = zObject({
  id: idSchema,
  title: z.string().min(1),
  quantity: z.number(),
  pricePerUnit: z.number(),
  unit: z.string().min(1),
  discountRate: z.number(),
  type: offerPositionTypeSchema,
  generatedByAutomation: generatedByAutomationSchema.nullish(),
  templateId: idSchema.nullish(),
  indexInOffer: z.number(),
  groupInOffer: z.number(),
  groupType: offerPositionGroupTypeSchema,
  isHidden: z.boolean().optional(),
  showPositionInOfferPdf: z.boolean(),
  includePositionToInvoice: z.boolean(),
  doInvoice: z.boolean().optional(),
  isCreatedFromIssuance: z.boolean().optional(),
  disableTitleTranslation: z.boolean().nullish(),
})

export const offerPositionMachineryAccessoryCategoryFilterSchema = zObject({
  producerCompanyName: z.string().optional().nullish(),
  typeName: z.string().optional().nullish(),
  storageLocation: z.string().optional().nullish(),
  drive: z.string().optional().nullish(),
  wheelSize: z.string().optional().nullish(),
  fem: z.string().optional().nullish(),
  liftingWeight: z.number().optional().nullish(),
  lengthInMillimeters: z.number().optional().nullish(),
  widthInMillimeters: z.number().optional().nullish(),
  heightInMillimeters: z.number().optional().nullish(),
  retractionLugsWidth: z.number().optional().nullish(),
  retractionLugsHeight: z.number().optional().nullish(),
  retractionLugsDistanceInnerEdgeToInnerEdge: z.number().optional().nullish(),
  retractionLugsDistanceOuterEdgeToOuterEdge: z.number().optional().nullish(),
  weightInKilograms: z.number().optional().nullish(),
  cubicMeters: z.number().optional().nullish(),
  distanceInnerEdgeToInnerEdge: z.number().optional().nullish(),
  distanceOuterEdgeToOuterEdge: z.number().optional().nullish(),
  comment: z.string().optional().nullish(),
  description: z.string().optional().nullish(),
  productCode: z.string().optional().nullish(),
  volt: z.string().optional().nullish(),
})

export const createOrUpdateMachineryOfferPositionSchema = createBaseOfferPositionSchema.extend({ type: z.literal('machinery'), machineryId: idSchema, operatingHours: z.number(), receptionMachinery: idObjectSchema.nullish(), repurchasePrice: z.number().min(0).nullish(), repurchasedAt: z.coerce.date().nullish() })

export const createOrUpdateOfferPositionSchema = zDiscriminatedUnion('type', [
  createOrUpdateMachineryOfferPositionSchema,
  createBaseOfferPositionSchema.extend({ type: z.literal('machineryAccessory'), machineryAccessoryId: idSchema, isForkReplaceRequired: z.boolean().nullish(), showSerialNumberInPdf: z.boolean().nullish(), isAttachedAccessoryHidden: z.boolean().default(false), compatibleMachineryAccessoryId: idSchema.nullish() }),
  createBaseOfferPositionSchema.extend({ type: z.literal('extraPosition'), templateId: idSchema }),
  createBaseOfferPositionSchema.extend({ type: z.literal('logisticsTask'), logisticsTaskId: idSchema.nullish(), disableTitleTranslation: z.boolean() }),
  createBaseOfferPositionSchema.extend({ type: z.literal('machineryAccessoryCategory'), machineryAccessoryCategory: z.string().min(1), isForkReplaceRequired: z.boolean().nullish(), compatibleMachineryAccessoryId: idSchema.nullish() }).merge(offerPositionMachineryAccessoryCategoryFilterSchema),
  createBaseOfferPositionSchema.extend({ type: z.literal('insurance'), templateId: idSchema }),
  createBaseOfferPositionSchema.extend({ type: z.literal('generatedByAutomation') }),
  createBaseOfferPositionSchema.extend({ type: z.literal('itemSet'), itemSetId: idSchema }),
  // All other types, except `itemSetAccessory`
  createBaseOfferPositionSchema.extend({ type: z.enum(['special', 'manualPosition', 'comment', 'creditNote', 'invoice', 'extraDays']) }),
])

export const rentalDaySchema = zObject({
  id: idSchema,
  date: z.coerce.date(),
  discountRate: z.number(),
})

export const breakDaySchema = zObject({
  id: idSchema,
  date: z.coerce.date(),
})

export const offerTypeSchema = z.enum(['sale', 'rental', 'special', 'service-project'])
export const offerCreateSchema = zObject({
  type: offerTypeSchema,
  isFavorite: z.boolean(),
  status: offerStatusSchema,
  obligationStartsAt: z.coerce.date(),

  deliveryAt: z.coerce.date().nullish(),

  paymentCondition: paymentConditions,

  customerId: idSchema,
  contactPersonId: idSchema.nullish(),
  deliveryLocation: z.string().nullish(),

  otherInvoiceRecipientId: idSchema.nullish(),

  doesCustomerDoTask: z.boolean().default(false),
  allowRentalPauseCreation: z.boolean().default(false),

  customerOrderNumber: z.string().nullish(),

  claimingPartnerName: z.string().nullish(),
  claimingPartnerEmail: z.string().nullish(),
  claimingPartnerTelephone: z.string().nullish(),

  secondClaimingPartnerName: z.string().nullish(),
  secondClaimingPartnerEmail: z.string().nullish(),
  secondClaimingPartnerTelephone: z.string().nullish(),

  thirdClaimingPartnerName: z.string().nullish(),
  thirdClaimingPartnerEmail: z.string().nullish(),
  thirdClaimingPartnerTelephone: z.string().nullish(),

  isAccessoryOnlyOffer: z.boolean().default(false),
  isConfirmed: z.boolean().nullish(),
  isCompleted: z.boolean().nullish(),

  positions: createOrUpdateOfferPositionSchema.array(),
  documents: pathInObjectFilesSchema,

  // rental specific
  rentalDays: rentalDaySchema.array().default([]),
  obligationEndsAt: z.coerce.date().nullable(),
  obligationActuallyEndedAt: z.coerce.date().nullish(),

  // service project specific
  description: z.string().nullish(),
  title: z.string().nullish(),
  requiresDailyReports: z.boolean().nullish(),

  // special specific
  isStorageSpaceOffer: z.boolean().optional(),
  projectCode: z.string().nullish(),

  wasFullyCompleted: z.boolean().nullish(),
  wasFullyCompletedComment: z.string().nullish(),
  hadExtraExpenses: z.boolean().nullish(),
  hadExtraExpensesComment: z.string().nullish(),
  hadCustomerDelays: z.boolean().nullish(),
  hadCustomerDelaysComment: z.string().nullish(),
  hadExternalDelays: z.boolean().nullish(),
  hadExternalDelaysComment: z.string().nullish(),
  hadExtraMaterials: z.boolean().nullish(),
  hadExtraMaterialsComment: z.string().nullish(),
  hadExtraWorkDone: z.boolean().nullish(),
  hadExtraWorkDoneComment: z.string().nullish(),
  hadDefects: z.boolean().nullish(),
  hadDefectsComment: z.string().nullish(),
  completionComment: z.string().nullish(),
  projectLeadName: z.string().nullish(),
  projectLeadSignature: z.string().nullish(),
  customerName: z.string().nullish(),
  customerSignature: z.string().nullish(),
  completionPhotos: pathInObjectFilesSchema,
  breakDays: breakDaySchema.array().default([]),
  isInternalProject: z.boolean().optional(),
  requestedAt: z.coerce.date(),

  contactForCustomerEmail: z.string().nullish(),

  // Data to help transfer Comments
  temporaryCommentIdToConvert: idSchema.nullish(),
})

// it is possible to create an offer without a `Contact Person` or `Delivery Location`. See https://github.com/sidestream-tech/hanselmann-os/issues/441 for details
export const mandatoryFieldsForRentalOfferToOrder = {
  type: z.literal('rental'),
  contactPersonId: idSchema,
  customerId: idSchema,
  deliveryLocation: z.string().min(1),
}

const mandatoryFieldsForSaleOfferToOrder = {
  type: z.literal('sale'),
  contactPersonId: idSchema,
  customerId: idSchema,
}

const mandatoryFieldsForServiceProjectInquiryToOffer = zObject({
  type: z.literal('service-project'),
  status: z.literal('offer'),

  obligationEndsAt: z.date(),
})

const mandatoryFieldsForServiceProjectOfferToOrder = zObject({
  type: z.literal('service-project'),
  status: z.literal('order'),

  contactPersonId: idSchema,
  claimingPartnerName: z.string(),
  claimingPartnerTelephone: z.string(),
})

export const mandatoryFieldsForServiceProject = zDiscriminatedUnion('status', [
  mandatoryFieldsForServiceProjectInquiryToOffer,
  mandatoryFieldsForServiceProjectOfferToOrder,
])

const mandatoryFieldsForSpecialOfferToOrder = {
  type: z.literal('special'),
  contactPersonId: idSchema,
}

/**
 * Schema to determine if an offer technically has enough data to be converted to an order. Limitations:
 * - we do not check the positions exactly
 */
const offerCreateWithoutTypeAndPositions = offerCreateSchema.omit({ type: true, positions: true }).extend({ positions: z.array(z.any()) })
const offerCreateTypeDiscriminate = zDiscriminatedUnion('type', [
  offerCreateWithoutTypeAndPositions.extend(mandatoryFieldsForRentalOfferToOrder),
  offerCreateWithoutTypeAndPositions.extend(mandatoryFieldsForSaleOfferToOrder),
  offerCreateWithoutTypeAndPositions.extend(mandatoryFieldsForSpecialOfferToOrder),
])

// todo: reduce repetition of refine
export const doesOfferHaveRequiredDataToBecomeOrderSchema = offerCreateTypeDiscriminate
  .refine(({ status }) => status !== 'invoiced')
  .refine(({ type, obligationEndsAt }) => {
    if (type === 'rental' && !obligationEndsAt) {
      return false
    }

    return true
  }, { message: 'One of the offer creation checks did not succeed' })

export const offerUpdateSchema = offerCreateSchema.extend({ id: idSchema }).refine(({ type, obligationEndsAt, isStorageSpaceOffer, positions }) => {
  if (type === 'rental' && !obligationEndsAt) {
    return false
  }

  if (type === 'special') {
    const hasMachineryAccessoryPosition = positions.some(position => ['machineryAccessoryCategory', 'machineryAccessory'].includes(position.type))
    if (!obligationEndsAt && isStorageSpaceOffer) {
      return false
    } else if (!isStorageSpaceOffer && hasMachineryAccessoryPosition) {
      return false
    }
  }

  return true
}, { message: 'One of the offer creation checks did not succeed' })

export const offerModelChangeSchema = offerCreateSchema.merge(idObjectSchema).extend({
  signedAb: z.string().nullish(),
  saleProtocolPdf: z.string().nullish(),
  machineryConvertedAt: z.coerce.date().nullish(),
  paidAmountWithoutInvoice: z.number().nullish(),
})

export const CRUDModeSchema = z.enum(['update', 'create'])
export const zodStringAsBoolean = z.string().transform(val => val === 'true')

export const logisticsViewSchema = z.enum(['issuance', 'issued', 'store', 'terminations', 'collections', 'collected', 'cancelled'])

// Oneway type logisticsTask status to be saved in Database
const logisticsTaskOnewaySchema = z.enum(['created', 'loaded', 'delivered'])
// Oneway type logisticsTask status to be shown on browser
export const logisticsTaskOnewayFullStatusSchema = z.enum(['created', 'planned', 'issued', 'loaded', 'delivered'])
// Roundtrip type logisticsTask status to be saved in Database
export const logisticsTaskRoundtripStatusSchema = z.enum(['created', 'loaded', 'delivered', 'terminated', 'collected', 'returned'])
// Roundtrip type logisticsTask status to be shown on browser
export const logisticsTaskRoundtripFullStatusSchema = z.enum(['created', 'planned', 'issued', 'loaded', 'delivered', 'terminated', 'collected', 'returned'])

export const logisticsTaskAToBStatusSchema = z.enum(['created', 'delivered', 'completed'])

export const manuallyCreatableLogisticsTypes = z.enum(['outbound', 'a-to-b'])

const logisticsTaskCreateBaseSchema = zObject({
  offerId: idSchema,
  plannedDeliveryAt: z.coerce.date(),
  doAllowPositionAdditionDuringIssuance: z.boolean().optional(),
  positionAdditionDuringIssuanceComment: z.string().optional(),
})

const createLogisticsTaskTypeATOBSchema = logisticsTaskCreateBaseSchema.extend({
  type: z.literal('a-to-b'),
  status: logisticsTaskAToBStatusSchema,
  deliveryFrom: z.string().min(1),
  deliveryTo: z.string().min(1),
})

const createLogisticsTaskTypeOutboundSchema = logisticsTaskCreateBaseSchema.extend({
  type: z.literal('outbound'),
  status: logisticsTaskOnewaySchema,
  doesFitterDoTask: z.boolean().default(false),
  positionsToShip: z.array(z.string()).optional(),
  trailerPosition: zDiscriminatedUnion('type', [
    zObject({ type: z.literal('machineryAccessory'), machineryAccessoryId: idSchema }),
    zObject({ type: z.literal('machineryAccessoryCategory'), machineryAccessoryCategory: z.string() }),
  ]).optional(),
})

export const createLogisticsTask = zDiscriminatedUnion('type', [
  createLogisticsTaskTypeATOBSchema,
  createLogisticsTaskTypeOutboundSchema,
])

export const updateLogisticsTask = zDiscriminatedUnion('type', [
  createLogisticsTaskTypeATOBSchema.merge(idObjectSchema),
  createLogisticsTaskTypeOutboundSchema.merge(idObjectSchema),
])

export const updateLogisticsTaskStatusSchema = zDiscriminatedUnion('type', [
  idObjectSchema.extend({ type: z.literal('outbound'), status: logisticsTaskOnewaySchema }),
  idObjectSchema.extend({ type: z.literal('inbound'), status: logisticsTaskRoundtripStatusSchema }),
  idObjectSchema.extend({ type: z.literal('a-to-b'), status: logisticsTaskAToBStatusSchema }),
])

export const logisticsMachineryDetailsSchema = zObject({
  tankFilling: z.number().int().min(0).nullish(),
  adblueFilling: z.number().int().min(0).nullish(),
  isTankFull: z.boolean().nullish(),
  uvvValiditedAt: z.coerce.date().nullish(),
  ignitionKeys: z.number().nullish(),
  gasTanks: z.number().int().min(0).nullish(),
  emptyGasTanks: z.number().int().min(0).nullish(),
  tuvValiditedAt: z.coerce.date().nullish(),
  securityCheckValiditedAt: z.coerce.date().nullish(),
})

export const logisticsFunctionalDetailsSchema = zObject({
  areEngineAndGearboxWorking: z.boolean().nullish(),
  isBreakWorking: z.boolean().nullish(),
  isSteerwheelWorking: z.boolean().nullish(),
  areMastAndChainAndBearingWorking: z.boolean().nullish(),
  isElectricalSystemWorking: z.boolean().nullish(),
  isMachineryAccessory: z.boolean().nullish(),
  isSecurityEquipmentWorking: z.boolean().nullish(),
  isLightingWorking: z.boolean().nullish(),
  isBatteryWorking: z.boolean().nullish(),
  areWarningLightAndHornWorking: z.boolean().nullish(),
  isSafetyShutdownWorking: z.boolean().nullish(),
  isHydraulicSystemWorking: z.boolean().nullish(),
  isGpsWorking: z.boolean().nullish(),
  note: z.string().nullish(),
})

export const logisticsVisualDetailsPollutionsSchema = z.enum(['weak', 'strong', 'none'])

export const logisticsVisualDetailsSchema = zObject({
  isMachineryComplete: z.boolean().nullish(),
  hasExternalDamage: z.boolean().nullish(),
  isLeaking: z.boolean().nullish(),
  isOilLevelOk: z.boolean().nullish(),
  isCoolantLevelOk: z.boolean().nullish(),
  isMachineryCleaned: z.boolean().nullish(),
  pollution: logisticsVisualDetailsPollutionsSchema.nullish(),
  isPollutionDiscounted: z.boolean().nullish(),
  isTapeMeasureAvailable: z.boolean().nullish(),
  note: z.string().nullish(),
})

export const logisticsConditionRatingSchema = z.enum(['new', 'good', 'middle', 'damaged'])
export const logisticsConditionDetailsSchema = zObject({
  roofAndCabin: logisticsConditionRatingSchema.nullish(),
  tiresAirAndSE: logisticsConditionRatingSchema.nullish(),
  forkesAndShovels: logisticsConditionRatingSchema.nullish(),
  painting: logisticsConditionRatingSchema.nullish(),
  driverSeat: logisticsConditionRatingSchema.nullish(),
  note: z.string().nullish(),
})

export const logisticsAccessoryDetailsSchema = zObject({
  id: idSchema.optional(),
  isMachineryAccessoryComplete: z.boolean().nullish(),
  hasExternalDamage: z.boolean().nullish(),
  isFunctioning: z.boolean().nullish(),
  note: z.string().nullish(),
  photos: filesFieldSchemaNullish,
})

export const logisticsAccessoryDetailsSchemaWithCategory = logisticsAccessoryDetailsSchema.merge(zObject({
  machineryAccessoryCategory: z.string().nullish(),
  machineryAccessoryId: idSchema.nullish(),
  itemSetId: idSchema.nullish(),
}))

export const upsertLogisticsDetailsSchema = zObject({
  id: idSchema,
  taskType: logisticsTaskTypeSchema,
  comment: z.string().nullish(),
  generalPhotos: pathInObjectFilesSchema,
  innerCabinPhotos: pathInObjectFilesSchema,
  outerCabinPhotos: pathInObjectFilesSchema,
  tiresPhotos: pathInObjectFilesSchema,
  enginePhotos: pathInObjectFilesSchema,
  logisticsMachineryDetails: logisticsMachineryDetailsSchema.optional(),
  logisticsFunctionalDetails: logisticsFunctionalDetailsSchema.optional(),
  logisticsVisualDetails: logisticsVisualDetailsSchema.optional(),
  logisticsConditionDetails: logisticsConditionDetailsSchema.optional(),
  logisticsAccessoryDetails: logisticsAccessoryDetailsSchemaWithCategory.optional(),
  isIssued: z.boolean(),
  issuedAt: z.coerce.date(),
  issuedByEmail: z.string().min(1),
  isReturned: z.boolean(),
  returnedAt: z.coerce.date().nullish(),
  returnedByEmail: z.string().nullish(),
  storageLocation: z.string().nullish(),
  receptionMachinery: idObjectSchema.extend({ operatingHours: z.number().nullish(), operatingHoursUpdatedAt: z.date().nullish(), operatingHoursUpdatedByEmail: z.string().nullish() }).nullish(),
})

export const logisticsTasksSortSchema = zObject({
  deliveryAt: z.enum(['asc', 'desc']).default('asc').optional(),
  offer: zObject({
    deliveryAt: z.enum(['asc', 'desc']).optional(),
  }).optional(),
}).optional()

// When update `commentTypeSchema`, please add Id to `createComment`
export const commentTypeSchema = z.enum([
  'logisticsTask',
  'offer',
  'serviceProject',
  'offerFeedback',
  'offerCustomer',
  'customer',
  'termination',
  'deliveryDate',
  'deliveryTime',
  'invoice',
  'internalCostPosition',
  'defect',
  'userTask',
  'itemSet',
  'machinery',
  'specialPermission',
  'machineryAccessory',
  'temporary',
  'storagePosition',
  'cancelledStoragePosition',
  'verifiedStoragePosition',
  'verifiedStoragePositionSet',
  'offloadedStoragePositionSet',
  'externalStorageInvoicingEvent',
])

export const createComment = zObject({
  type: commentTypeSchema,
  text: z.string().optional(),
  files: pathInObjectFilesSchema,
  customerId: idSchema.nullish(),
  offerId: idSchema.nullish(),
  serviceProjectId: idSchema.nullish(),
  offerFeedbackId: idSchema.nullish(),
  logisticsTaskId: idSchema.nullish(),
  deliveryDateLogisticsId: idSchema.nullish(),
  deliveryTimeLogisticsId: idSchema.nullish(),
  invoiceId: idSchema.nullish(),
  internalCostPositionId: idSchema.nullish(),
  defectId: idSchema.nullish(),
  userTaskId: idSchema.nullish(),
  itemSetId: idSchema.nullish(),
  machineryId: idSchema.nullish(),
  specialPermissionId: idSchema.nullish(),
  machineryAccessoryId: idSchema.nullish(),
  temporaryId: idSchema.nullish(),
  storagePositionId: idSchema.nullish(),
  cancelledStoragePositionId: idSchema.nullish(),
  verifiedStoragePositionId: idSchema.nullish(),
  verifiedStoragePositionSetId: idSchema.nullish(),
  offloadedStoragePositionSetId: idSchema.nullish(),
  externalStorageInvoicingEventId: idSchema.nullish(),
  offerCustomerId: idSchema.nullish(),
})

export const updateComment = createComment.merge(idObjectSchema)

export const calendarPageOfferCreationModeEmitSchema = zObject({
  machinery: zObject({
    id: idSchema,
    producerCompanyName: z.string().min(1),
    type: zObject({
      name: z.string().min(1),
    }),
    operatingHours: z.number(),
  }).nullish(),
  cartPositions: z.array(z.discriminatedUnion('type', [
    z.object({ type: z.literal('machineryAccessory'), machineryAccessoryId: idSchema, machineryAccessoryCategory: z.string(), isForkReplaceRequired: z.boolean().nullish(), compatibleMachineryAccessoryId: idSchema.nullish() }),
    z.object({ type: z.literal('machineryAccessoryCategory'), machineryAccessoryCategory: z.string(), isForkReplaceRequired: z.boolean().nullish(), compatibleMachineryAccessoryId: idSchema.nullish(), filters: offerPositionMachineryAccessoryCategoryFilterSchema }),
  ])),
  itemSets: z.array(idObjectSchema.extend({ title: z.string().min(1) })),
  selectedStartTime: z.coerce.date(),
  rentalDuration: z.number(),
  isAccessoryOnlyOffer: z.boolean(),
})

export const createFromOtherOfferSchema = zObject({
  offerId: idSchema,
  calendarPositions: calendarPageOfferCreationModeEmitSchema,
  deliveryAt: z.coerce.date(),
})

export const logisticsDeliveryCommentTypeSchema = z.enum(['deliveryTime', 'deliveryDate'])

export const invoiceViewSchema = z.enum(['archive', 'payment', 'preparation'])

export const invoiceType = z.enum(['downpayment', 'partialpayment', 'fullpayment', 'creditNote', 'proformapayment'])
// invoice types selected from invoice creation popup
export const invoiceTypeToSelect = z.enum(['downpayment', 'partialpayment', 'fullpayment', 'proformapayment'])

export const invoiceStatus = z.enum(['created', 'pending', 'partiallyPaid', 'paid', 'cancelled', 'notInvoiceable'])

export const createInvoiceBaseSchema = zObject({
  status: z.string(invoiceStatus).default('created'),
  offerId: idSchema,
  totalAmount: z.number(),
  includeVAT: z.boolean().default(true),
  invoicedAt: z.coerce.date(),
  startDate: z.coerce.date().optional(),
  endDate: z.coerce.date().optional(),
})

export const createInvoiceToOfferPositionRelationSchema = zObject({
  quantity: z.number().min(0),
  pricePerUnit: z.number().min(0),
  discountRate: z.number().min(0),
  offerPositionId: idSchema,
  invoicedDate: z.coerce.date().optional(),
  discountRateForDay: z.number().min(0).optional(),
})

export const createInvoiceCustomPositionSchema = createBaseOfferPositionSchema.extend({ type: z.enum(['invoice', 'comment']) })
export const createCreditNoteCustomPositionSchema = createBaseOfferPositionSchema.extend({
  type: z.enum(['creditNote', 'comment']),
  machineryId: idSchema.nullish(),
  machineryAccessoryId: idSchema.nullish(),
  machineryAccessoryCategory: z.string().nullish(),
  itemSetId: idSchema.nullish(),
})

export const createInvoiceCreditNoteSchema = createInvoiceBaseSchema.extend({
  type: z.literal('creditNote'),
  creditNoteForInvoiceId: idSchema,
  offerPositions: idObjectSchema.array(),
  customPositions: createCreditNoteCustomPositionSchema.array(),
})

export const createInvoiceExtraDaysPositionSchema = createBaseOfferPositionSchema.omit({ id: true }).extend({
  type: z.literal('extraDays'),
  machineryId: idSchema.nullish(),
  machineryAccessoryId: idSchema.nullish(),
  offerPositionToExtendId: idSchema,
})

export const createProformaInvoiceSchema = createInvoiceBaseSchema.extend({
  type: z.literal('proformapayment'),
  offerPositions: createInvoiceToOfferPositionRelationSchema.array(),
  customPositions: createInvoiceCustomPositionSchema.array(),
})

export const createInvoiceSchema = zDiscriminatedUnion('type', [
  createInvoiceBaseSchema.extend({ type: z.literal('downpayment') }),
  createInvoiceBaseSchema.extend({
    type: z.literal('partialpayment'),
    offerPositions: createInvoiceToOfferPositionRelationSchema.array(),
    customPositions: createInvoiceCustomPositionSchema.array(),
    downpaymentInvoices: idObjectSchema.array(),
  }),
  createInvoiceBaseSchema.extend({
    type: z.literal('fullpayment'),
    offerPositions: createInvoiceToOfferPositionRelationSchema.array(),
    customPositions: createInvoiceCustomPositionSchema.array(),
    downpaymentInvoices: idObjectSchema.array(),
    extraDaysPositions: createInvoiceExtraDaysPositionSchema.array(),
  }),
  createInvoiceCreditNoteSchema,
]).refine(data => !data.startDate || !data.endDate || data.endDate >= data.startDate, {
  message: 'End date must be greater than or equal to start date',
  path: ['endDate', 'startDate'],
})

export const offerPositionPaymentStatus = z.enum(['open', 'mixed', 'invoiced'])

export const internalCostPositionType = z.enum(['machinery', 'offer'])

export const defectType = z.enum(['machinery', 'machineryAccessory'])
export const defectPriority = z.number().int().min(0).max(1)

export const modelChangesType = z.enum(['offer'])

export const paginationSchema = zObject({
  skip: z.number().nonnegative(),
  take: z.number().nonnegative(),
}).optional()

const userTaskBaseSchema = zObject({
  title: z.string().min(1),
  description: z.string().default(''),
  startAt: z.coerce.date().nullish(),
  dueAt: z.coerce.date(),
  assignedToRole: userRoleSchema.nullable(),
  assignedToUsers: emailSchema.array().default([]),
  relatedOfferId: idSchema.nullable().default(null),
  temporaryCommentIdToConvert: idSchema.nullish(),
  relatedStoragePositionId: idSchema.nullish(),
})

export const userTaskCreateSchema = userTaskBaseSchema.refine(value => value.assignedToRole || value.assignedToUsers.length > 0, { message: 'Either role or email must be assigned to task' })
export const userTaskUpdateSchema = userTaskBaseSchema.merge(idObjectSchema).refine(value => value.assignedToRole || value.assignedToUsers.length > 0, { message: 'Either role or email must be assigned to task' })

export const userOfferMembershipRoleSchema = z.enum(['commercial-project-manager', 'on-site-manager', 'project-member'])

export const userOfferMembershipCreateOrUpdateSchema = zObject({
  offerId: idSchema,
  userEmail: z.string().email().transform(v => v.toLocaleLowerCase()),
  role: userOfferMembershipRoleSchema,
  standardWorkingDays: z.array(z.nativeEnum(StandardWorkingDays)),
})

export const createInquirySchema = zObject({
  user: zObject({
    name: z.string(),
    email: z.string().email(),
  }),
  task: userTaskCreateSchema,
  documents: pathInObjectFilesSchema,
})

export const createQuickSaleSchema = zObject({
  machineryId: idSchema,
  price: z.number().nullish(),
  description: z.string(),
  additionalInformation: z.string().nullish(),
  includeVAT: z.boolean().default(true),
})

export const storageUserCreateOrUpdateSchema = zObject({
  name: z.string().min(1).nullable(),
  email: emailSchema,
  storageCustomerId: idSchema,
})

export const marketingMediaUpdateSchema = zObject({
  id: idSchema,
  marketingPhotos: pathInObjectFilesSchema,
  marketingVideos: pathInObjectFilesSchema,
  externalMarketingVideos: filesFieldSchemaNullish,
})

export const positionTemplateBundleCreateSchema = zObject({
  title: z.string(),
  templateToBundleRelations: zObject({
    template: zObject({ id: idSchema, title: z.string() }),
    order: z.number(),
  }).array(),
  offerType: offerTypeSchema,
})

export const positionTemplateBundleUpdateSchema = positionTemplateBundleCreateSchema.merge(idObjectSchema)

export const storagePositionOffloadedWithSchema = z.nativeEnum(StoragePositionOffloadedWith)

export const storagePositionStatusSchema = z.nativeEnum(StoragePositionStatus)

export const serviceProjectTimeTrackingEntryStatusSchema = z.enum(['done', 'open', 'cancelled'])

export const partialStoragePositionLoadSchema = z.object({
  id: idSchema,
  loadedWith: storagePositionOffloadedWithSchema,
  loadedWithOther: z.string().min(1).nullish().transform(value => value ?? null),
  loadingPhotos: pathInObjectFilesSchema,
  deliveryVehicleId: idSchema.nullable(),
  deliveryTrailerLicensePlate: z.string().min(1),
})

export const rentalPauseUpsertSchema = z.object({
  id: idSchema.optional(),
  offerId: idSchema,
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
})
