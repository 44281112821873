<script setup lang="ts">
const i18n = useI18n()

const { openReleaseMachineryForOnlineSalesPopup: { data, close } } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const updateMachinery = useMutation({
  mutationFn: $trpc.machinery.updatePriceHanselmannPubliclySellsFor.mutate,
  onError: makeTrpcErrorToast(notification, { description: i18n.t('onlineSales.updateMachinery.onError.description') }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['machines'] })
    close()
    notification.success({ title: i18n.t('onlineSales.updateMachinery.onSuccess.title'), duration: 4500 })
  },
})

interface SubmitFormData {
  priceHanselmannPubliclySellsFor: number
  machineryIds: string[]
}
function onSubmitForm(value: SubmitFormData) {
  const popupData = data.value
  if (!popupData) {
    return
  }

  updateMachinery.mutate({
    ...value,
    machineryIds: popupData.machineryIds,
  })
}
</script>

<template>
  <ThePopup
    v-if="data"
    :show="true"
    :title="$t('machinery.releaseMachineryPopup.title')"
    @close="close"
  >
    <TheDataCard>
      <n-collapse class="mb-4">
        <n-collapse-item :title="$t('machinery.releaseMachineryPopup.dataCard.title')">
          <ul class="ml-8 list-disc grid grid-rows-1 grid-cols-5 grid-flow-auto">
            <li v-for="machineryId in data.machineryIds" :key="machineryId">
              {{ machineryId }}
            </li>
          </ul>
        </n-collapse-item>
      </n-collapse>
      <FormKit
        v-slot="{ value }"
        type="form"
        :submit-label="$t('onlineSales.submit.label')"
        @submit="onSubmitForm"
      >
        <TheDevOnlyNiceJson :value="value" />
        <FormKit
          name="priceHanselmannPubliclySellsFor"
          type="number"
          step="0.01"
          min="0"
          :label="$t('onlineSales.priceHanselmannPubliclySellsFor.label')"
          placeholder="40000"
          validation="required"
          number="float"
          :help="$t('onlineSales.priceHanselmannPubliclySellsFor.help')"
        />
      </FormKit>
    </TheDataCard>
  </ThePopup>
</template>
