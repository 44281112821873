import { machineryDrivesToGerman, machineryStatusToGerman } from '../translations'
import type { ApiMachineryGetById, ApiPublicMachineryGetById, ApiReceptionMachineryGetById, MachineryDrives, MachineryStatus } from '~/types'

const machineryBaseInformation: OneDataColumn<ApiMachineryGetById>[] = [
  {
    title: 'ID',
    key: 'id',
  },
  {
    title: 'Status',
    key: 'status',
    render: ({ status }) => machineryStatusToGerman[status as MachineryStatus],
  },
]

const machineryPublicType: OneDataColumn<{ name: string }>[] = [
  {
    title: 'Typ',
    key: 'name',
  },
]

const machineryType: OneDataColumn<ApiPublicMachineryGetById['type']>[] = [
  {
    title: 'Typ',
    key: 'name',
  },
]

function machineryPublicVehicleData(serverI18n?: I18n): OneDataColumn<ApiPublicMachineryGetById>[] {
  const i18n = serverI18n ?? useNuxtApp().$i18n

  return [
    {
      title: 'Hersteller',
      key: 'producerCompanyName',
      hideIfNullish: true,
    },
    {
      title: 'Rubrik',
      key: 'machineryRubric',
      render: ({ machineryRubric }) => i18n.t(`machinery.rubric.${machineryRubric}`),
      hideIfNullish: true,
    },
    {
      title: 'Typ',
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.type?.name,
      hideIfNullish: true,
    },
    {
      title: 'Baujahr',
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.yearBuilt,
      hideIfNullish: true,
    },
    {
      title: 'Fabrikat',
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.product,
      hideIfNullish: true,
    },
    {
      title: 'Antrieb',
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.drive ? machineryDrivesToGerman[receptionMachinery.drive.name as MachineryDrives] : undefined,
      hideIfNullish: true,
    },
    {
      title: 'Gewicht (kg)',
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.weight,
      hideIfNullish: true,
    },
    {
      title: 'Betriebsstunden',
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.operatingHours ? (receptionMachinery.operatingHours + 50).toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
  ]
}

function machineryPublicDimensions(serverI18n?: I18n): OneDataColumn<ApiPublicMachineryGetById>[] {
  const i18n = serverI18n ?? useNuxtApp().$i18n

  return [
    {
      title: 'Mast',
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.mast ? i18n.t(`machinery.mast.${receptionMachinery.mast}`) : undefined,
      hideIfNullish: true,
    },
    {
      title: 'Hubhöhe (mm)',
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.liftingHeightInMillimeters ? receptionMachinery.liftingHeightInMillimeters.toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
    {
      title: 'Bauhöhe (mm)',
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => {
        const heightInMillimeters = receptionMachinery?.mastOverallHeightInMillimeters ?? receptionMachinery?.overallHeightInMillimeters
        return heightInMillimeters ? heightInMillimeters.toLocaleString('de-DE') : undefined
      },
      hideIfNullish: true,
    },
    {
      title: 'Gabelträgerbreite (mm)',
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.forkCarriageWidthInMillimeters ? receptionMachinery.forkCarriageWidthInMillimeters.toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
    {
      title: 'Fem',
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.fem,
      hideIfNullish: true,
    },
  ]
}

const machineryPublicMeasurements: OneDataColumn<ApiPublicMachineryGetById>[] = [
  {
    title: 'Länge bis zum Gabelträger (mm)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.lengthToForkfaceInMillimeters ? receptionMachinery.lengthToForkfaceInMillimeters.toLocaleString('de-DE') : undefined,
    hideIfNullish: true,
  },
  {
    title: 'Gesamtlänge (mm)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.totalLengthInMillimeters ? receptionMachinery.totalLengthInMillimeters.toLocaleString('de-DE') : undefined,
    hideIfNullish: true,
  },
  {
    title: 'Radstand (mm)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.wheelbaseInMillimeters ? receptionMachinery.wheelbaseInMillimeters.toLocaleString('de-DE') : undefined,
    hideIfNullish: true,
  },
  {
    title: 'Baubreite (mm)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.overallWidthInMillimeters ? receptionMachinery.overallWidthInMillimeters.toLocaleString('de-DE') : undefined,
    hideIfNullish: true,
  },
  {
    title: 'Bodenfreiheit Mitte',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.groundClearanceCenter,
    hideIfNullish: true,
  },
  {
    title: 'Höhe Kabine (mm)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.cabinHeightInMillimeters ? receptionMachinery.cabinHeightInMillimeters.toLocaleString('de-DE') : undefined,
    hideIfNullish: true,
  },
]

const machineryPublicBatteryData: OneDataColumn<ApiPublicMachineryGetById>[] = [
  {
    title: 'Hersteller',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.battery?.producerCompanyName,
    hideIfNullish: true,
  },
  {
    title: 'Typ',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.battery?.typeName,
    hideIfNullish: true,
  },
  {
    title: 'Gewicht (kg)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.battery?.weight ? receptionMachinery.battery.weight.toLocaleString('de-DE') : undefined,
    hideIfNullish: true,
  },
  {
    title: 'Volt',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.battery?.volt,
    hideIfNullish: true,
  },
  {
    title: 'Baujahr',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.battery?.yearBuilt,
    hideIfNullish: true,
  },
]

const machineryPublicMotorData: OneDataColumn<ApiPublicMachineryGetById>[] = [
  {
    title: 'Motor Hersteller',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.motor?.producerCompanyName,
    hideIfNullish: true,
  },
  {
    title: 'Leistung (KW)',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.motor?.power ? receptionMachinery.motor.power.toLocaleString('de-DE') : undefined,
    hideIfNullish: true,
  },
  {
    title: 'Typ',
    key: 'receptionMachinery',
    render: ({ receptionMachinery }) => receptionMachinery?.motor?.typeName,
    hideIfNullish: true,
  },
]

const receptionMachinerySpecialEquipment: OneDataColumn<ApiReceptionMachineryGetById | Exclude<ApiPublicMachineryGetById['receptionMachinery'], null>>[] = [
  {
    title: 'Sonderausstattung',
    key: 'receptionMachinerySpecialEquipmentRelation',
    render: ({ receptionMachinerySpecialEquipmentRelation, customSpecialEquipment }) => {
      const specialEquipments = receptionMachinerySpecialEquipmentRelation?.receptionMachinerySpecialEquipments?.map(type => type.typeName).join(', ')
      if (!specialEquipments && !customSpecialEquipment) {
        return undefined
      }
      return `${specialEquipments}${customSpecialEquipment ? `${specialEquipments ? ', ' : ''}${customSpecialEquipment}` : ''}`
    },
    hideIfNullish: true,
  },
]

const machineryEquipment: OneDataColumn<ApiPublicMachineryGetById>[] = [
  {
    title: 'Anbaugeräte',
    key: 'marketingMachineryAccessoryDescription',
  },
  {
    title: 'Sonderausstattung',
    key: 'marketingSpecialEquipmentDescription',
  },
]

export default {
  machineryBaseInformation,
  machineryPublicType,
  machineryType,
  machineryPublicVehicleData,
  machineryPublicDimensions,
  machineryPublicMeasurements,
  machineryPublicBatteryData,
  machineryPublicMotorData,
  receptionMachinerySpecialEquipment,
  machineryEquipment,
}
